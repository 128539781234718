import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import * as actions from '../../../actions/fileExplorerActions';
import { areIntersected } from '../../../common/helpers';
import { IFile } from '../../../reducers/fileExplorerReducer';
import Movable from './Movable';
import { IFileItem } from './DirectoryView';

interface IDispatchProps {
  select: (files: IFile[]) => void;
}

interface IOwnProps {
  fileItems: IFileItem[];
}

type IProps = IOwnProps & IDispatchProps;

class SelectBox extends Movable<IProps> {
  private selectedFiles: IFile[] = [];

  render() {
    return <div className="select-box"></div>;
  }

  protected onMouseMove(event: MouseEvent) {
    super.onMouseMove(event);

    if (this.isMoving && this.self && this.parent
      && this.x1 !== undefined && this.y1 !== undefined
      && this.x2 !== undefined && this.y2 !== undefined
    ) {
      if (!this.parent.classList.contains('hide-checkbox')) {
        this.parent.classList.add('hide-checkbox');
      }

      if (!this.self.classList.contains('visible')) {
        this.self.classList.add('visible');
      }

      const left = Math.min(this.x1, this.x2);
      const top = Math.min(this.y1, this.y2);

      this.setStyle({
        left,
        top,
        width: Math.abs(this.x1 - this.x2),
        height: Math.abs(this.y1 - this.y2)
      });

      const selectRect = this.self.getBoundingClientRect();

      const { fileItems } = this.props;
      const nextSelectedFileItems = fileItems
        .filter(item => areIntersected(item.ref.current && item.ref.current.getBoundingClientRect(), selectRect));

      const nextSelectedFiles = nextSelectedFileItems.map(r => r.file);

      if (!_.isEqual(this.selectedFiles, nextSelectedFiles)) {
        fileItems
          .forEach(r => r.ref.current && (r.ref.current as HTMLElement).classList.remove('selected'));

        nextSelectedFileItems
          .forEach(r => r.ref.current && (r.ref.current as HTMLElement).classList.add('selected'));

        this.selectedFiles = nextSelectedFiles;
      }
    }
  }

  protected onMouseUp(event: MouseEvent) {
    if (this.isMoving) {
      const { select } = this.props;
      select(this.selectedFiles);

      if (this.self) {
        this.self.classList.remove('visible');
      }

      if (this.parent) {
        this.parent.classList.remove('hide-checkbox');
      }
    }

    super.onMouseUp(event);
  }

  private setStyle(style: object) {
    if (this.self) {
      const map = {
        width: 'px',
        height: 'px',
        top: 'px',
        left: 'px',
      };

      const str = Object.keys(style).map(k => `${k}:${style[k]}${map[k] || ''}`).join(';');
      this.self.setAttribute('style', str);
    }
  }
}

const mapDispatchToProps: IDispatchProps = {
  select: actions.select
};

export default connect(undefined, mapDispatchToProps)(SelectBox);
