import axios from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { subscribeUrl } from '../common';
import {
  SubscribeFormActionTypes,
  ISubscribeAction,
  ISubscribeVerifiedUserBehaviorAction,
  subscribeSucceeded,
  subscribeFailed
} from '../actions/subscribeFormActions';
import { verifyUserBehavior } from '../actions/recaptchaActions';

const intension = 'subscribe';

const subscribeApi = (email: string, recaptchaToken: string) => {
  return axios.put(subscribeUrl, {
    email,
    action: intension
  }, {
    headers: {
      'X-Recaptcha': recaptchaToken
    }
  });
};

function* subscribeForm(action: ISubscribeAction) {
  yield put(verifyUserBehavior({
    intension,
    nextActionType: SubscribeFormActionTypes.SUBSCRIBE_VERIFIED_USER_BEHAVIOR,
    nextActionPayload: action.payload
  }));
}

function* verifiedUserBehavior(action: ISubscribeVerifiedUserBehaviorAction) {
  const { recaptchaToken, data } = action.payload;
  const email = data;

  try {
    yield call(subscribeApi, email, recaptchaToken);
    yield put(subscribeSucceeded());
  } catch (err) {
    yield put(subscribeFailed((err.response && err.response.data.message) || err.message));
  }
}

export function* watchSubscribeForm() {
  yield all([
    takeEvery(SubscribeFormActionTypes.SUBSCRIBE, subscribeForm),
    takeEvery(SubscribeFormActionTypes.SUBSCRIBE_VERIFIED_USER_BEHAVIOR, verifiedUserBehavior)
  ]);
}
