const policyVersion = '0.0.1';
const key = 'policy-version';

export function isStoragePermitted(): boolean {
  return localStorage.getItem(key) === policyVersion;
}

export function permitStorage() {
  localStorage.setItem(key, policyVersion);
}

export function denyStorage() {
  localStorage.removeItem(key);
}
