import React, { PureComponent, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import validator from 'validator';
import { Typography, TextField, Button, Checkbox, FormControlLabel } from '@material-ui/core';
import { highlight } from '../../../common/helpers';
import { IAppState } from '../../../store';
import * as subscribeFromActions from '../../../actions/subscribeFormActions';
import * as browserDataPermissionActions from '../../../actions/browserDataPermissionActions';
import { Link, Snackbar } from '../../../common/components';

import './SubscribeForm.scss';

interface IState {
  email: string;
  errorMessageVisible: boolean;
  emailStoringAllowed: boolean;
}

interface IStateProps {
  browserDataPermitted: boolean;
  snackbarMessage?: string;
  subscribeSucceeded?: boolean;
}

interface IDispatchProps {
  subscribe: (email: string) => void;
  eraseSubscribeMessage: () => void;
  highlightBrowserDataPermissionConsent: () => void;
}

type IProps = IStateProps & IDispatchProps;

class Subscribe extends PureComponent<IProps, IState> {
  private subscribeInputRef?: HTMLInputElement | null;
  private checkboxRef?: HTMLElement | null;

  constructor(props) {
    super(props);

    this.state = {
      email: '',
      emailStoringAllowed: false,
      errorMessageVisible: false
    };

    this.onSubscribeSubmit = this.onSubscribeSubmit.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.onCheckboxChanged = this.onCheckboxChanged.bind(this);
    this.onEmailChange = this.onEmailChange.bind(this);
  }

  componentDidUpdate() {
    const { browserDataPermitted, subscribeSucceeded } = this.props;
    const { emailStoringAllowed } = this.state;

    if (browserDataPermitted && emailStoringAllowed) {
      this.setErrorMessageVisibility(false);
    }

    if (subscribeSucceeded) {
      this.setState({
        ...this.state,
        email: '',
        emailStoringAllowed: false
      });
    }
  }

  render() {
    const { email, emailStoringAllowed, errorMessageVisible } = this.state;

    return <div className="subscribe-form">
      <Typography variant="caption" className="re-captcha">
        *This site is protected by reCAPTCHA and
        the Google <a className="link"
          href="https://policies.google.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          Privacy Policy
        </a> and <a className="link"
          href="https://policies.google.com/terms"
          target="_blank"
          rel="noopener noreferrer"
        >
          Terms of Service
        </a> apply.
      </Typography>
      <form onSubmit={this.onSubscribeSubmit}>
        <div className="subscribe-container">
          <div className="subscribe">
            <TextField
              value={this.state.email}
              label="Email"
              onChange={this.onEmailChange}
              inputRef={ref => this.subscribeInputRef = ref}
              helperText="* Permissions to store data and process email should be accepted"
              FormHelperTextProps={
                {
                  error: true,
                  hidden: !errorMessageVisible
                }
              }
            />
            <Button
              type="submit"
              variant="outlined"
              disabled={!validator.isEmail(email)}
            >
              Subscribe
            </Button>
          </div>
          <FormControlLabel
            innerRef={ref => this.checkboxRef = ref}
            control={
              <Checkbox color="primary" checked={emailStoringAllowed} onChange={this.onCheckboxChanged} />
            }
            label={<>I allow to store my email
            and send newsletters for me. <Link className="link" to="/privacy">More</Link></>}
          />
        </div>
      </form>
      <Typography variant="h6">Follow us in social networks</Typography>
      {this.renderSnackbars()}
    </div>;
  }

  private renderSnackbars() {
    const { subscribeSucceeded, snackbarMessage, } = this.props;
    return <>
      <Snackbar
        variant={'success'}
        open={subscribeSucceeded === true}
        message={snackbarMessage || ''}
        onClose={this.onSnackbarClose}
      />
      <Snackbar
        variant={'error'}
        open={subscribeSucceeded === false}
        message={snackbarMessage || ''}
        onClose={this.onSnackbarClose}
      />
    </>;
  }

  private async onSubscribeSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    const { subscribe, browserDataPermitted, highlightBrowserDataPermissionConsent } = this.props;
    const { email, emailStoringAllowed } = this.state;

    if (emailStoringAllowed && browserDataPermitted) {
      subscribe(email);

      if (this.subscribeInputRef) {
        this.subscribeInputRef.blur();
      }

    } else {
      this.setErrorMessageVisibility(true);

      if (!emailStoringAllowed) {
        this.highlightSubscribeCheckbox();
      }

      if (!browserDataPermitted) {
        highlightBrowserDataPermissionConsent();
      }
    }
  }

  private setErrorMessageVisibility(visible: boolean) {
    this.setState({
      ...this.state,
      errorMessageVisible: visible
    });
  }

  private setEmailStoringAllowed(allowed: boolean) {
    this.setState({
      ...this.state,
      emailStoringAllowed: allowed
    });
  }

  private setEmail(email: string) {
    this.setState({
      ...this.state,
      email
    });
  }

  private highlightSubscribeCheckbox() {
    highlight(this.checkboxRef);
  }

  private onEmailChange(event: ChangeEvent<HTMLInputElement>) {
    this.setEmail(event.target.value);
  }

  private onSnackbarClose() {
    this.props.eraseSubscribeMessage();
  }

  private onCheckboxChanged(event: ChangeEvent, checked: boolean) {
    this.setEmailStoringAllowed(checked);
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const {
    subscribeSucceeded,
    subscribeResultMessage
  } = store.subscribeForm;
  const { permitted } = store.browserDataPermission;

  return {
    browserDataPermitted: Boolean(permitted),
    subscribeSucceeded,
    snackbarMessage: subscribeResultMessage
  };
};

const mapDispatchToProps: IDispatchProps = {
  subscribe: subscribeFromActions.subscribe,
  eraseSubscribeMessage: subscribeFromActions.eraseSubscribeResultMessage,
  highlightBrowserDataPermissionConsent: browserDataPermissionActions.highlightConsent
};

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe);
