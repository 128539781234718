import React from 'react';
import { List, ListItem, ListItemIcon } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faTwitterSquare } from '@fortawesome/free-brands-svg-icons';

import './FollowUs.scss';

export default ({ onClick }: { onClick?: () => void }) => <List className="follow-us-list">
  {[
    {
      name: 'facebook',
      path: 'https://www.facebook.com/archyvault',
      icon: <FontAwesomeIcon className="icon" icon={faFacebook} />
    },
    {
      name: 'instagram',
      path: 'https://www.instagram.com/archyvault',
      icon: <FontAwesomeIcon className="icon" icon={faInstagram} />
    },
    {
      name: 'twitter',
      path: 'https://twitter.com/archyvault',
      icon: <FontAwesomeIcon className="icon" icon={faTwitterSquare} />
    }
  ].map(({ name, path, icon }) => (
    <ListItem button key={name} component="a" href={path} target="_blank" onClick={onClick}>
      <ListItemIcon>{icon}</ListItemIcon>
    </ListItem>
  ))}
</List>;
