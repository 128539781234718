import React from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { ProgressBar } from '../../../common/components';

import './UploadProgress.scss';

interface IStateProps {
  readonly currentFileName?: string;
  readonly currentFileUploadedBytes?: number;
  readonly currentFileTotalBytes?: number;
  readonly totalUploadedBytes?: number;
  readonly totalBytesToUpload?: number;
  readonly totalUploadedFiles?: number;
  readonly totalFilesToUpload?: number;
}

function UploadProgress(props: IStateProps) {
  const {
    currentFileName,
    currentFileUploadedBytes,
    currentFileTotalBytes,
    totalUploadedBytes,
    totalBytesToUpload,
    totalUploadedFiles,
    totalFilesToUpload
  } = props;

  if (currentFileName === undefined ||
    currentFileUploadedBytes === undefined ||
    currentFileTotalBytes === undefined ||
    totalUploadedBytes === undefined ||
    totalBytesToUpload === undefined ||
    totalUploadedFiles === undefined ||
    totalFilesToUpload === undefined
  ) {
    throw Error('File Progress: missing required props');
  }

  return <div className="upload-progress">
    {
      [
        { value: currentFileUploadedBytes / currentFileTotalBytes, details: currentFileName },
        { value: totalUploadedBytes / totalBytesToUpload, details: `${totalUploadedFiles} / ${totalFilesToUpload}` }
      ]
        .map(({ value, details }, index) =>
          <div key={index} className="upload-progress-section">
            <ProgressBar value={value} />
            <div className="details">{details}</div>
          </div>
        )
    }
  </div>;
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const {
    currentFileName,
    currentFileUploadedBytes,
    currentFileTotalBytes,
    totalUploadedBytes,
    totalBytesToUpload,
    totalUploadedFiles,
    totalFilesToUpload
  } = store.upload;

  return {
    currentFileName,
    currentFileUploadedBytes,
    currentFileTotalBytes,
    totalUploadedBytes,
    totalBytesToUpload,
    totalUploadedFiles,
    totalFilesToUpload
  };
};

export default connect(mapStateToProps)(UploadProgress);
