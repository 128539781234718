import { all, call, takeEvery, put, take } from 'redux-saga/effects';
import { FacebookActionTypes, init, changedStatus, disconnected } from '../actions/facebookActions';
import {
  BrowserDataPermissionActionTypes,
  IInitBrowserDataPermissionAction,
  ISetBrowserDataPermissionAction
} from '../actions/browserDataPermissionActions';
import { listenFacebookChannel } from '../channels/facebookChannel';

function* initFacebook() {
  const channel = yield call(listenFacebookChannel);

  try {
    while (true) {
      const status = yield take(channel);
      yield put(changedStatus(status));
    }
  } finally {
    yield put(disconnected());
  }
}

function* runFacebook(action: IInitBrowserDataPermissionAction | ISetBrowserDataPermissionAction) {
  const browserDataPermitted = action.payload;
  if (browserDataPermitted) {
    yield put(init());
  }
}

function* refreshFacebook() {
  const FB = (window as any).FB;
  if (FB) {
    yield call(FB.XFBML.parse);
  }
}

export function* watchFacebook() {
  yield all([
    takeEvery(FacebookActionTypes.INIT_FACEBOOK, initFacebook),
    takeEvery(FacebookActionTypes.REFRESH_FACEBOOK, refreshFacebook),
    takeEvery(BrowserDataPermissionActionTypes.INIT_BROWSER_DATA_PERMISSION, runFacebook),
    takeEvery(BrowserDataPermissionActionTypes.SET_BROWSER_DATA_PERMISSION, runFacebook)
  ]);
}
