import React, { PureComponent } from 'react';
import DropArea from '../DropArea';
import UploadProgress from '../UploadProgress';
import { Link } from '../../../common/components';

import './RightPanel.scss';
import ResourceAllocation from '../ResourceAllocation';

class RightPanel extends PureComponent<{}> {
  render() {
    return (
      <div className="right-panel">
        <div className="right-panel-top">
          <ResourceAllocation />
          <Link className="link" to="/faq">More</Link>
        </div>
        <div className="right-panel-bottom">
          <UploadProgress />
          <DropArea />
        </div>
      </div>
    );
  }
}

export default RightPanel;
