import React, { PureComponent } from 'react';
import clsx from 'clsx';
import WorkIcon from '@material-ui/icons/Work';
import { Typography } from '@material-ui/core';
import { Footer, Section, Link } from '../../common/components';

import './HowItWorks.scss';

export default class HowItWorks extends PureComponent {
  render() {
    return <div className="how-it-works blog-page">
      {
        [
          {
            icon: <WorkIcon className="icon" />,
            title: 'How It Works',
            content: this.renderHowItWorks()
          }
        ].map((section, index) => <Section key={index} {...section} />)
      }
      <Footer />
    </div>;
  }

  private renderHowItWorks() {
    return <>
      {
        [
          {
            title: '1. Upload your files to Archyvault',
            description: `When you upload a file, 2 copies are created: with original quality and reduced quality.
              Those copies are stored on different storage types in the cloud.`,
            imageName: 'upload-files',
            doubleColumn: true
          },
          {
            title: '2. Download original quality files from Archival Storage',
            description: `Files in the reduced quality, you can start downloading any time.
              However, if you want to download an original quality file, you need to request it first,
              and your file will become available for download in 12 hours.`,
            imageName: 'download-from-archival-storage'
          },
          {
            title: '3. Review or download reduced quality files any time',
            description: `Reduced quality files are available for review and download at any time.
              You can use them while waiting for original files.`,
            imageName: 'download-from-fast-storage'
          }
        ].map(({ title, description, imageName, doubleColumn }) =>
          this.renderStep(title, description, imageName, doubleColumn))
      }
      <Typography variant="body1">
        More questions? Check out
        our <Link className="link" to="/faq">FAQ page</Link>.
        You can get 30GB of Archive Storage for Free -
        follow <Link className="link" to="/register">Register</Link> link.
      </Typography>
    </>;
  }

  private renderStep(title: string, description: string, imageName: string, doubleColumn?: boolean) {
    const alt = imageName.replace('-', ' ');
    return <div key={title} className="step">
      <Typography variant="h6">{title}</Typography>
      <Typography variant="body1">{description}</Typography>
      <img
        className="scheme"
        src={`images/how-it-works/${imageName}.svg`}
        alt={alt}
      />
      <img
        className={clsx('scheme-mobile', doubleColumn && 'double-column')}
        src={`images/how-it-works/${imageName}-mobile.svg`}
        alt={alt}
      />
    </div>;
  }
}
