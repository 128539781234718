import Chart, * as chart from './Chart';
import { drawPieChart } from '../../helpers';

class PieChart extends Chart<chart.IOwnProps> {
  protected async drawChart() {
    const { data, series } = this.props;

    const options = {
      ...this.createInitialChartOptions(series),
      pieSliceText: 'none',
      pieSliceBorderColor: 'none'
    };

    if (this.selfRef) {
      await drawPieChart(this.selfRef, data, options);
    }
  }
}

export default PieChart;
