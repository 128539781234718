import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { IAppState } from '../../../store';
import { IDirectory, IFile } from '../../../reducers/fileExplorerReducer';
import FileExplorerItem from '../FileExplorerItem';
import * as actions from '../../../actions/fileExplorerActions';
import {
  convertRemToPixels,
  locateFileExplorerItem,
  getItemsInRow,
  getItemsRangeToRender,
  getPositions
} from '../../../common/helpers';
import ContextMenu from './ContextMenu';
import SelectBox from './SelectBox';
import DnD from './DnD';

export interface IFileItem {
  file: IFile;
  ref: React.RefObject<HTMLButtonElement>;
}

export interface IRenderItemsRange {
  first: number;
  last: number;
}

interface IState {
  itemsRangeToRender: IRenderItemsRange;
}

interface IStateProps {
  selected: IFile[];
}

interface IOwnProps {
  currentDirectory: IDirectory;
}

interface IDispatchProps {
  select: (file: IFile) => void;
  open: (file: IFile) => void;
  toggleSelected: (file: IFile) => void;
  openContextMenu: (menuElement?: HTMLElement) => void;
}

type IProps = IStateProps & IOwnProps & IDispatchProps;

const itemWidth = convertRemToPixels(6);
const itemHeight = convertRemToPixels(6);
const space = 1;

class DirectoryView extends PureComponent<IProps, IState> {
  private readonly hiddenRowsCount = 5;

  private visibleFileItems: IFileItem[] = [];
  private elementRefs: Array<React.RefObject<HTMLButtonElement>> = [];

  private scrollbarsRef?: HTMLElement | null;
  private containerRef?: HTMLElement | null;

  constructor(props) {
    super(props);

    this.state = {
      itemsRangeToRender: { first: 0, last: 100 }
    };

    this.onClick = this.onClick.bind(this);
    this.onDoubleClick = this.onDoubleClick.bind(this);

    this.onContextMenu = this.onContextMenu.bind(this);
    this.refreshRenderedRange = _.debounce(this.refreshRenderedRange, 100).bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.refreshRenderedRange);
    window.addEventListener('scroll', this.refreshRenderedRange);

    this.refreshRenderedRange();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.refreshRenderedRange);
    window.removeEventListener('scroll', this.refreshRenderedRange);
  }

  render() {
    const { currentDirectory } = this.props;
    const itemsInRow = getItemsInRow(itemWidth, this.containerRef);

    this.updateViewSize(currentDirectory, itemsInRow);
    this.updateVisibleItems(currentDirectory);

    return <div className="directory-view">
      <div
        ref={ref => this.scrollbarsRef = ref}
        className="files-container"
        onClick={this.onClick}
        onDoubleClick={this.onDoubleClick}
        onContextMenu={this.onContextMenu}
        onScroll={this.refreshRenderedRange}
      >
        <div
          ref={ref => this.containerRef = ref}
          className="file-explorer-items-container">
          {
            this.renderItems(itemsInRow)
          }
        </div>
      </div>
      <ContextMenu />
      <SelectBox fileItems={this.visibleFileItems} />
      <DnD fileItems={this.visibleFileItems} currentDirectory={currentDirectory} />
    </div>;
  }

  private onClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const { currentDirectory, select, toggleSelected } = this.props;
    const fileItem = locateFileExplorerItem(event.target, this.visibleFileItems);

    if (fileItem) {
      const checkBoxNode = (event.target as HTMLElement).closest('.checkbox');

      if (checkBoxNode) {
        toggleSelected(fileItem.file);
        return;
      }

      select(fileItem.file);
      return;
    }

    select(currentDirectory);
  }

  private onDoubleClick(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const { open } = this.props;
    const fileExplorerItem = locateFileExplorerItem(event.target, this.visibleFileItems);

    if (fileExplorerItem) {
      open(fileExplorerItem.file);
    }
  }

  private onContextMenu(event: React.MouseEvent<HTMLElement, MouseEvent>) {
    const { select, currentDirectory, openContextMenu } = this.props;

    event.preventDefault();
    const fileItem = locateFileExplorerItem(event.target, this.visibleFileItems);

    if (fileItem) {
      select(fileItem.file);
      openContextMenu(fileItem.ref.current || undefined);

      return;
    }

    select(currentDirectory);
  }

  private updateViewSize(currentDirectory: IDirectory, itemsInRow: number) {
    if (this.containerRef) {
      this.containerRef.style.height = `${Math.ceil(currentDirectory.children.length / itemsInRow) * (itemHeight + space)}px`;
    }
  }

  private updateVisibleItems(currentDirectory: IDirectory) {
    const { first, last } = this.state.itemsRangeToRender;

    const visibleChildren = _.orderBy(currentDirectory.children, (file: IFile) => ([file.fileType]))
      .slice(first, last + 1);

    if (this.elementRefs.length < visibleChildren.length) {
      this.elementRefs.push(
        ..._.times(visibleChildren.length - this.elementRefs.length, () => React.createRef<HTMLButtonElement>())
      );
    }

    this.visibleFileItems = visibleChildren.map((file, index) => {
      return { file, ref: this.elementRefs[index] };
    });
  }

  private renderItems(itemsInRow: number) {
    const { selected } = this.props;

    const positions = getPositions(
      this.visibleFileItems,
      this.state.itemsRangeToRender,
      itemWidth,
      itemHeight,
      space,
      itemsInRow
    );

    return this.visibleFileItems.map(({ file, ref }, index) => {
      const { left, top } = positions[index];

      return <FileExplorerItem
        key={file.id}
        innerRef={ref}
        file={file}
        isSelected={Boolean(selected.find(f => f.id === file.id))}
        style={{ left, top }}
      />;
    });
  }

  private refreshRenderedRange() {
    const { currentDirectory } = this.props;

    const range = getItemsRangeToRender(
      this.scrollbarsRef,
      this.containerRef,
      itemWidth,
      itemHeight,
      currentDirectory.children.length,
      this.hiddenRowsCount
    );

    this.setItemsRangeToRender(range);
  }

  private setItemsRangeToRender(range: IRenderItemsRange) {
    this.setState({
      ...this.state,
      itemsRangeToRender: range
    });
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { selected } = store.fileExplorer;
  return {
    selected
  };
};

const mapDispatchToProps: IDispatchProps = {
  select: (file: IFile) => actions.select([file]),
  open: actions.open,
  toggleSelected: actions.toggleSelected,
  openContextMenu: actions.openContextMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(DirectoryView);
