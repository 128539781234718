import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from '../../../common/components';

export default class QuickTour extends PureComponent<{}> {
  render() {
    return <div className="quick-tour">
      <Typography variant="body1">
        We have a small video tour for you to get more familiar with Archyvault!
      </Typography>
      <div className="embedded-video-container">
        <div className="embedded-video-wrapper">
          <iframe
            title="facebook"
            className="embedded-video"
            width="1066" height="600"
            src="https://www.youtube-nocookie.com/embed/rzDe1wUO4dw"
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen>
          </iframe>
        </div>
      </div>
      <Typography variant="body1">
        More about how Archyvault works, you can find <Link className="link" to="/how-it-works">here</Link>.
      </Typography>
    </div>;
  }
}
