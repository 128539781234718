import React from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';

import './ProgressBar.scss';

export default function ProgressBar({ value }: { value: number }) {
  const displayValue = value * 100;

  return <div className="progress-bar">
    <div className="caption">{displayValue}%</div>
    <LinearProgress variant="determinate" value={displayValue} />
  </div>;
}
