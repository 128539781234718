import { Reducer } from 'redux';
import {
  FaqActions,
  FaqActionTypes
} from '../actions/faqActions';
import { convertTextToQuestionAnswerList } from '../common/helpers';

export interface IFaqState {
  readonly questionAnswerList: Array<{ question: string, answer: string[] }>;
}

const initialState: IFaqState = {
  questionAnswerList: []
};

export const faqReducer: Reducer<IFaqState, FaqActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FaqActionTypes.GET_FAQ_SUCCEEDED: {
      return {
        ...state,
        questionAnswerList: convertTextToQuestionAnswerList(action.payload)
      };
    }
    default:
      return state;
  }
};
