import React from 'react';
import { Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';

export default ({ onClick }: { onClick?: () => void }) => (
  <Link to="/" onClick={onClick} className="site-title">
    <img src="/logo.svg" className="logo" alt="logo" />
    <Typography variant="h1">Archyvault</Typography>
  </Link>
);
