import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { SwipeableDrawer, List, ListItem, ListItemIcon, ListItemText, Divider } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeIcon from '@material-ui/icons/Home';
import FolderIcon from '@material-ui/icons/Folder';
import WorkIcon from '@material-ui/icons/Work';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import HelpIcon from '@material-ui/icons/Help';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import Title from './Title';

import { IAppState } from '../../../store';
import * as actions from '../../../actions/navigationActions';
import FollowUs from '../FollowUs';
import Link from '../Link';

interface IStateProps {
  isDrawerOpen: boolean;
}

interface IDispatchProps {
  toggleDrawer: () => void;
}

type IProps = IStateProps & IDispatchProps;

class Drawer extends PureComponent<IProps> {
  constructor(props) {
    super(props);

    this.onToggle = this.onToggle.bind(this);
  }

  render() {
    const { isDrawerOpen } = this.props;
    return <SwipeableDrawer className="drawer" open={isDrawerOpen} onClose={this.onToggle} onOpen={this.onToggle}>
      <Title onClick={this.onToggle} />
      <Divider />
      <List>
        {[
          { name: 'Home', path: '/', icon: <HomeIcon className="icon" /> },
          { name: 'My Files (Demo)', path: '/my-files', icon: <FolderIcon className="icon" /> },
          { name: 'How It Works', path: '/how-it-works', icon: <WorkIcon className="icon" /> },
          { name: 'Storage Costs', path: '/#pricing', icon: <FontAwesomeIcon icon={faTag} className="icon" /> },
          { name: 'Subscribe', path: '/#subscribe-and-follow', icon: <NotificationsActiveIcon className="icon" /> },
          { name: 'Register', path: '/register', icon: <HowToRegIcon className="icon" /> },
          { name: 'FAQ', path: '/faq', icon: <HelpIcon className="icon" /> }
        ].map(({ name, path, icon }) => (
          <ListItem button key={name} component={Link} to={path} onClick={this.onToggle}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={name} />
          </ListItem>
        ))}
      </List>
      <div className="bottom-container">
        <Divider />
        <List dense>
          {[
            { name: 'Terms and Conditions', path: '/terms-and-conditions' },
            { name: 'Privacy', path: '/privacy' },
            { name: 'Contact Us:', path: 'mailto:info@archyvault.com', externalLink: 'info@archyvault.com' }
          ].map(({ name, path, externalLink }) => {
            if (externalLink) {
              return <ListItem key={name}>
                <ListItemText primary={name} secondary={
                  <a href={path} className="link" target="_blank" rel="noopener noreferrer">{externalLink}</a>
                } />
              </ListItem>;
            }
            return <ListItem button key={name} component={Link} to={path} onClick={this.onToggle}>
              <ListItemText primary={name} />
            </ListItem>;
          })}
        </List>
        <Divider />
        <div className="follow-us-container">
          <div className="follow-us">FOLLOW US</div>
          <FollowUs onClick={this.onToggle} />
        </div>
      </div>
    </SwipeableDrawer>;
  }

  private onToggle() {
    const { toggleDrawer } = this.props;
    toggleDrawer();
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { navigation } = store;
  return {
    isDrawerOpen: navigation.isDrawerOpen
  };
};

const mapDispatchToProps: IDispatchProps = {
  toggleDrawer: actions.toggleDrawer
};

export default connect(mapStateToProps, mapDispatchToProps)(Drawer);
