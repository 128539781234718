import { all, call, takeEvery, put } from 'redux-saga/effects';
import ReactGA from 'react-ga';
import { AnalyticsActionTypes, init } from '../actions/analyticsActions';
import { googleAnalyticsAppId } from '../common';
import {
  BrowserDataPermissionActionTypes,
  IInitBrowserDataPermissionAction,
  ISetBrowserDataPermissionAction
} from '../actions/browserDataPermissionActions';

function analyticsApi() {
  ReactGA.initialize(googleAnalyticsAppId);
  ReactGA.pageview(window.location.pathname + window.location.search);
}

function* initAnalytics() {
  yield call(analyticsApi);
}

function* runAnalytics(action: IInitBrowserDataPermissionAction | ISetBrowserDataPermissionAction) {
  const browserDataPermitted = action.payload;
  if (browserDataPermitted) {
    yield put(init());
  }
}

export function* watchAnalytics() {
  yield all([
    takeEvery(AnalyticsActionTypes.INIT_ANALYTICS, initAnalytics),
    takeEvery(BrowserDataPermissionActionTypes.INIT_BROWSER_DATA_PERMISSION, runAnalytics),
    takeEvery(BrowserDataPermissionActionTypes.SET_BROWSER_DATA_PERMISSION, runAnalytics)
  ]);
}
