import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { IAppState } from '../../store';
import * as actions from '../../actions/termsAndConditionsActions';

import './TermsAndConditions.scss';
import { Section, Footer } from '../../common/components';

interface IStateProps {
  dateStr: string;
  sections: Array<{ title: string, text: string[] }>;
}

interface IDispatchProps {
  get: () => void;
}

type IProps = IStateProps & IDispatchProps;

class TermsAndConditions extends PureComponent<IProps> {
  componentWillMount() {
    this.props.get();
  }

  render() {
    const { dateStr, sections } = this.props;

    return <div className="terms-and-conditions blog-page">
      {
        [
          {
            className: 'title-section',
            content: <Typography variant="h1">Terms and Conditions</Typography>
          },
          {
            className: 'last-updated',
            content: <Typography variant="body2">{dateStr}</Typography>
          },
          ...sections.map(({ title, text }, index) => ({
            title,
            text,
            className: index % 2 !== 0 ? 'with-background' : undefined
          })),
          {
            title: 'Contact Us',
            content: <Typography variant="body1">
              In order to resolve a complaint regarding the Site or to receive
              further information regarding the use of the Site, please contact us
                at: <a href="mailto:info@archyvault.com" className="link">
                info@archyvault.com
                </a>
            </Typography>
          }
        ].map((section, index) => <Section key={index} {...section} />)
      }
      <Footer />
    </div>;
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { dateStr, sections } = store.termsAndConditions;

  return {
    dateStr,
    sections
  };
};

const mapDispatchToProps: IDispatchProps = {
  get: actions.get
};

export default connect(mapStateToProps, mapDispatchToProps)(TermsAndConditions);
