import React, { PureComponent } from 'react';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import { amber, red } from '@material-ui/core/colors';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const styles = {
  success: {
    backgroundColor: '#349F68',
  },
  error: {
    backgroundColor: red[600],
  },
  info: {
    // backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: '20px',
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
};

interface IOwnProps {
  open: boolean;
  message: string;
  variant: 'success' | 'warning' | 'error' | 'info';
  onClose: () => void;
}

interface IClassNames { classes: { [className in keyof typeof styles]: string }; }

class MySnackbar extends PureComponent<IOwnProps & IClassNames> {
  render() {
    const { open, onClose, variant, message, classes } = this.props;

    const Icon = variantIcon[variant];
    return <Snackbar
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={open}
      autoHideDuration={6000}
      onClose={onClose}
    >
      <SnackbarContent
        className={classes[variant]}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton key="close" aria-label="Close" color="inherit" onClick={onClose}>
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    </Snackbar>;
  }
}

export default withStyles(styles)(MySnackbar);
