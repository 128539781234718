import { IRenderItemsRange, IFileItem } from '../../components/MyFiles/FileExplorer/DirectoryView';

export function getItemsInRow(itemWidth: number, container: HTMLElement | null | undefined) {
  if (container) {
    return Math.trunc(container.clientWidth / itemWidth);
  }

  return 1;
}

export function getItemsRangeToRender(
  scrollbars: HTMLElement | null | undefined,
  container: HTMLElement | null | undefined,
  itemWidth: number,
  itemHeight: number,
  totalItems: number,
  hiddenRowsCount: number
): IRenderItemsRange {
  if (scrollbars && container) {
    const itemsInRow = getItemsInRow(itemWidth, container);
    const hiddenItemsCount = hiddenRowsCount * itemsInRow;

    let first = Math.trunc(document.documentElement.scrollTop / itemHeight) * itemsInRow
      - hiddenItemsCount;

    first = first < 0 ? 0 : first;

    let last = first
      + Math.trunc(document.documentElement.clientHeight / itemHeight) * itemsInRow
      + hiddenItemsCount * 2
      - 1;

    last = last >= totalItems ? totalItems - 1 : last;

    return { first, last };
  }

  return { first: 1, last: 1 };
}

export function getPositions(
  fileItems: IFileItem[],
  itemsRangeToRender: IRenderItemsRange,
  itemWidth: number,
  itemHeight: number,
  space: number,
  itemsInRow: number
) {
  let i = -1;
  let j = Math.trunc(itemsRangeToRender.first / itemsInRow);

  return fileItems.map(() => {
    i++;

    if (i === itemsInRow) {
      i = 0;
      j++;
    }

    return { left: i * (itemWidth + space), top: j * (itemHeight + space) };
  });
}
