import axios from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  TermsAndConditionsActionTypes,
  getSucceeded,
  getFailed
} from '../actions/termsAndConditionsActions';

const termsAndConditionsApi = () => {
  return axios.get('/content/en-us/terms-and-conditions.txt');
};

function* getTermsAndConditions() {
  try {
    const { data } = yield call(termsAndConditionsApi);
    yield put(getSucceeded(data));
  } catch (err) {
    yield put(getFailed());
  }
}

export function* watchTermsAndConditions() {
  yield all([
    takeEvery(TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS, getTermsAndConditions)
  ]);
}
