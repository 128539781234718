import { Reducer } from 'redux';
import { UploadActions, UploadActionTypes } from '../actions/uploadActions';

export interface IUploadState {
  readonly currentFileName?: string;
  readonly currentFileUploadedBytes?: number;
  readonly currentFileTotalBytes?: number;
  readonly totalUploadedBytes?: number;
  readonly totalBytesToUpload?: number;
  readonly totalUploadedFiles?: number;
  readonly totalFilesToUpload?: number;
}

const defaultBytesToUpload = 274 * 1024 * 1024 * 8;
const defaultTotalFilesToUpload = 76;

const initialState: IUploadState = {
  currentFileName: 'image_1234.jpg',
  currentFileUploadedBytes: defaultBytesToUpload / defaultTotalFilesToUpload * 0.78,
  currentFileTotalBytes: defaultBytesToUpload / defaultTotalFilesToUpload,
  totalUploadedBytes: defaultBytesToUpload * 0.43,
  totalBytesToUpload: defaultBytesToUpload,
  totalUploadedFiles: 17,
  totalFilesToUpload: defaultTotalFilesToUpload
};

export const uploadReducer: Reducer<IUploadState, UploadActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case UploadActionTypes.UPLOAD_FILE_COMPLETED: {
      return {
        ...state,
        currentFileName: undefined
      };
    }
    default:
      return state;
  }
};
