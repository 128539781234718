import _ from 'lodash';

export function readLine(text: string, index: number): string {
  return text.split('\n')[index];
}

export function convertTextToSections(text: string): Array<{ title: string; text: string[] }> {
  const lines = text.split('\n').map(l => l.trim()).filter(l => l);
  const sectionIndexes = lines.reduce((acc: number[], line, index) => {
    if (/^[A-Z\s,]+$/.test(line)) {
      acc.push(index);
    }
    return acc;
  }, []);

  const sectionGroups = sectionIndexes.map((startSectionIndex, index) => {
    const endSectionIndex = sectionIndexes[index + 1] || lines.length;
    return lines.slice(startSectionIndex, endSectionIndex);
  });

  return sectionGroups.map(group => ({
    title: group[0],
    text: group.slice(1)
  }));
}

export function convertTextToQuestionAnswerList(text: string): Array<{ question: string; answer: string[] }> {
  const lines = text.split('\n').map(l => l.trim()).filter(l => l);
  const questionIndexes = lines.reduce((acc: number[], line, index) => {
    if (/^\d+\.\s.*$/.test(line)) {
      acc.push(index);
    }
    return acc;
  }, []);

  const questionAnswerGroups = questionIndexes.map((startSectionIndex, index) => {
    const endSectionIndex = questionIndexes[index + 1] || lines.length;
    return lines.slice(startSectionIndex, endSectionIndex);
  });

  return questionAnswerGroups.map(group => ({
    question: group[0].replace(/^\d+\.\s/, ''),
    answer: group.slice(1)
  }));
}

export function formatTitle(title: string): string {
  const shouldBeLowerCased = ['To', 'And', 'Of', 'If', 'With', 'For', 'A', 'An', 'The'];
  let str = _.startCase(_.lowerCase(title));

  if (title[title.length - 1] === '?') {
    str = `${str}?`;
  }

  const words = str.split(' ');

  shouldBeLowerCased.forEach(item => {
    words.forEach((word, index) => {
      if (item === word) {
        words[index] = word.toLowerCase();
      }
    });
  });

  return words.join(' ');
}

export function findNumeratedList(lines: string[]): { start: number, end: number } | undefined {
  const numeratedItemIndexes = lines.reduce((acc: number[], line, index) => {
    if (/^\d+/.test(line)) {
      acc.push(index);
    }
    return acc;
  }, []);

  if (numeratedItemIndexes.length) {
    return { start: numeratedItemIndexes[0], end: numeratedItemIndexes[numeratedItemIndexes.length - 1] };
  }
}

export function findOwnHttpAddress(text: string): Array<{ start: number, value: string }> | undefined {
  const expression = /(?<http_address>https:\/\/archyvault\.com.*?)(\s|\.|,|$)/g;
  const result: Array<{ start: number, value: string }> = [];

  let match: RegExpExecArray | null;

  do {
    match = expression.exec(text);
    if (match && match.groups) {
      result.push({ start: match.index, value: match.groups.http_address });
    }
  } while (match);

  if (result.length > 0) {
    return result;
  }
}

export function formatPrice(value: number) {
  return `${_.ceil(value, 2).toFixed(2)}`;
}

export function formatSize(size: number) {
  if (size < 1024) {
    return `${size}GB`;
  }

  return `${_.floor(size / 1024, 2).toFixed(2)}TB`;
}
