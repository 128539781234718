export function areIntersected(rect1: ClientRect | undefined | null, rect2: ClientRect | undefined | null): boolean {
  if (!rect1 || !rect2) {
    return false;
  }

  return !(rect1.left > rect2.right || rect1.right < rect2.left
    || rect1.top > rect2.bottom || rect1.bottom < rect2.top);
}

export function isInRect(point: { x: number, y: number }, rect: ClientRect | undefined | null) {
  if (!point || !rect) {
    return false;
  }

  return !(point.x < rect.left || point.x > rect.right
    || point.y < rect.top || point.y > rect.bottom);
}
