import React, { PureComponent } from 'react';
import _ from 'lodash';

import './Chart.scss';

export interface ISeries {
  [index: number]: { color: string };
}

export interface IOwnProps {
  title?: string;
  subtitle?: string;
  data: any[][];
  hiddenLegend?: boolean;
  series: ISeries;
}

abstract class Chart<TOwnProps extends IOwnProps> extends PureComponent<TOwnProps> {
  protected selfRef?: HTMLElement | null;
  private windowWidth = window.innerWidth;

  constructor(props) {
    super(props);
    this.onResize = _.debounce(this.onResize.bind(this), 500);
  }

  render() {
    const { title, subtitle, series, data, hiddenLegend } = this.props;
    const legendItemNames = !hiddenLegend && data[0].slice(1);

    return <div className="chart-container">
      {
        title && subtitle &&
        <>
          <div className="title">{title}</div>
          <div className="subtitle">{subtitle}</div>
        </>
      }
      {
        !hiddenLegend &&
        <ul className="legend">
          {
            Object.values(series).map((item, index) => {
              return <li key={index} className="legend-item">
                <div style={{ backgroundColor: item.color }} className="item-color-box"></div>
                <div className="caption">{legendItemNames[index]}</div>
              </li>;
            })
          }
        </ul>
      }
      <div ref={ref => this.selfRef = ref} className="chart"></div>
    </div>;
  }

  async componentDidMount() {
    await this.drawChart();
    window.addEventListener('resize', this.onResize);
    window.addEventListener('orientationchange', this.onResize);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('orientationchange', this.onResize);
  }

  protected abstract drawChart(): void;

  protected createInitialChartOptions(series: ISeries) {
    return {
      legend: { position: 'none' },
      // version 42
      colors: Object.values(series).map(({ color }) => color),
      // classic
      backgroundColor: 'transparent',
      fontName: 'Roboto',
      enableInteractivity: false
    };
  }

  private onResize() {
    if (this.windowWidth !== window.innerWidth) {
      this.windowWidth = window.innerWidth;
      this.drawChart();
    }
  }
}

export default Chart;
