import React from 'react';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import { IAppState } from '../../../store';
import { PieChart } from '../../../common/components';
import { convertBytesToGb } from '../../../common/helpers';

import './ResourceAllocation.scss';

interface IStateProps {
  readonly currentArchivalStorageBytes: number;
  readonly maxArchivalStorageBytes: number;
  readonly currentFreeMonthlyDownloadBytes: number;
  readonly maxFreeMonthlyDownloadBytes: number;
}

function ResourceAllocation(props: IStateProps) {
  const {
    currentArchivalStorageBytes,
    maxArchivalStorageBytes,
    currentFreeMonthlyDownloadBytes,
    maxFreeMonthlyDownloadBytes
  } = props;

  return <Grid container className="resource-allocation">
    {
      [
        {
          title: 'Archival Storage',
          colors: ['#b0c7df', '#306EAB'],
          current: currentArchivalStorageBytes,
          max: maxArchivalStorageBytes
        },
        {
          title: 'Monthly Download',
          colors: ['#9eddc2', '#28B478'],
          current: currentFreeMonthlyDownloadBytes,
          max: maxFreeMonthlyDownloadBytes
        }
      ].map(({ title, colors, current, max }, index) =>
        <Grid key={index} item xs>
          <div className="allocation-title">{title}</div>
          <PieChart
            hiddenLegend
            data={[
              ['Type', 'Value'],
              ['Allocated', current],
              ['Available', max - current]
            ]}
            series={colors.reduce((acc, curr, i) => {
              acc[i] = { color: curr };
              return acc;
            }, {})}
          />
          <div className="allocation-details">{convertBytesToGb(current)} / {convertBytesToGb(max)} GB</div>
        </Grid>
      )
    }
  </Grid>;
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const {
    currentArchivalStorageBytes,
    maxArchivalStorageBytes,
    currentFreeMonthlyDownloadBytes,
    maxFreeMonthlyDownloadBytes
  } = store.resourceAllocation;

  return {
    currentArchivalStorageBytes,
    maxArchivalStorageBytes,
    currentFreeMonthlyDownloadBytes,
    maxFreeMonthlyDownloadBytes
  };
};

export default connect(mapStateToProps)(ResourceAllocation);
