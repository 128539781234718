import { all, takeEvery, call } from 'redux-saga/effects';
import { extendUrl, updateFileInUrl, updateUrl } from '../common/helpers';
import {
  FileExplorerActionTypes,
  IFileExplorerOpenAction,
  IFileExplorerOpenInPlaceAction,
  IFileExplorerGoUpAction
} from '../actions/fileExplorerActions';

function* open(action: IFileExplorerOpenAction) {
  const file = action.payload;
  yield call(extendUrl, file);
}

function* openInPlace(action: IFileExplorerOpenInPlaceAction) {
  const file = action.payload;
  yield call(updateFileInUrl, file);
}

function* goUp(action: IFileExplorerGoUpAction) {
  const directory = action.payload;
  yield call(updateUrl, directory);
}

export function* watchFileExplorer() {
  yield all([
    takeEvery(FileExplorerActionTypes.OPEN, open),
    takeEvery(FileExplorerActionTypes.OPEN_IN_PLACE, openInPlace),
    takeEvery(FileExplorerActionTypes.GO_UP, goUp)
  ]);
}
