import React from 'react';
import { List, ListItem, ListItemText, Grid, Container } from '@material-ui/core';

import './Footer.scss';
import FollowUs from '../FollowUs';
import Link from '../Link';
import GradientLine from '../GradientLine';

export default () => <footer className="footer">
  <GradientLine className="top-line blue-green" />
  <Container>
    <Grid container className="section-container">
      <Grid item sm={4} xs={6} className="section">
        <List dense>
          {[
            { name: 'Follow Us:', content: <FollowUs /> },
          ].map(({ name, content }) => {
            return <ListItem key={name}>
              <ListItemText primary={name} />
              {content}
            </ListItem>;
          })}
        </List>
      </Grid>
      <Grid item sm={4} className="section">
        <div className="contact-us-wrapper">
          <List dense>
            {[
              { name: 'Contact Us:', path: 'mailto:info@archyvault.com', externalLink: 'info@archyvault.com' }
            ].map(({ name, path, externalLink }) => {
              return <ListItem key={name}>
                <ListItemText primary={name} secondary={
                  <a href={path} className="link" target="_blank" rel="noopener noreferrer">{externalLink}</a>
                } />
              </ListItem>;
            })}
          </List>
        </div>
      </Grid>
      <Grid item sm={4} xs={6} className="section">
        <List dense>
          {[
            { name: 'Terms and Conditions', path: '/terms-and-conditions' },
            { name: 'Privacy', path: '/privacy' },
          ].map(({ name, path }, index) => {
            return <ListItem key={index} className="link" component={Link} to={path}>
              <ListItemText primary={name} />
            </ListItem>;
          })}
        </List>
      </Grid>
    </Grid>
    <div className="copyright">Archyvault &copy; {new Date().getFullYear()}</div>
  </Container>
</footer>;
