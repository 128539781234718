import { eventChannel } from 'redux-saga';
import { appendScript } from '../common/helpers';

export function listenFacebookChannel() {
  return eventChannel(emitter => {
    appendScript('https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v4.0&appId=2332476740335136&autoLogAppEvents=1',
      () => {
        const FB = (window as any).FB;
        FB.Event.subscribe('auth.statusChange', onStatusChange);

        FB.getLoginStatus();
      });

    function onStatusChange(response: any) {
      const FB = (window as any).FB;
      if (response.status === 'connected') {
        FB.api('/me?fields=name,email', res => {
          emitter({ status: response.status, email: res.email, name: res.name });
        });
      } else {
        emitter({ status: response.status });
      }
    }

    return () => ({});
  });
}
