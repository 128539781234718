import React from 'react';
import moize from 'moize';
import FlashOn from '@material-ui/icons/FlashOn';
import { StorageTypes } from '../../../actions/fileExplorerActions';

const FileExplorerStorage = ({ storageType }: { storageType: StorageTypes }) =>
  storageType === StorageTypes.ARCHIVE
  ? <></>
  : <FlashOn className="storage" />;

export default moize.react(FileExplorerStorage, { maxSize: 2 });
