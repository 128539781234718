import { applyMiddleware, combineReducers, createStore, Store, StoreEnhancer } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/rootSaga';
import monitorReducerEnhancer from '../enhancers/monitorReducer';

import {
  navigationReducer,
  INavigationState
} from '../reducers/navigationReducer';

import {
  facebookReducer,
  IFacebookState
} from '../reducers/facebookReducer';

import {
  fileExplorerReducer,
  IFileExplorerState,
} from '../reducers/fileExplorerReducer';

import {
  resourceAllocationReducer,
  IResourceAllocationState,
} from '../reducers/resourceAllocationReducer';

import {
  uploadReducer,
  IUploadState,
} from '../reducers/uploadReducer';

import {
  ISubscribeFormState,
  subscribeFormReducer
} from '../reducers/subscribeFormReducer';

import {
  IBrowserDataPermissionState,
  browserDataPermissionReducer
} from '../reducers/browserDataPermissionReducer';

import {
  ITermsAndConditionsState,
  termsAndConditionsReducer
} from '../reducers/termsAndConditionsReducer';

import {
  IFaqState,
  faqReducer
} from '../reducers/faqReducer';

import logger from '../middleware/logger';

export interface IAppState {
  navigation: INavigationState;
  facebook: IFacebookState;
  fileExplorer: IFileExplorerState;
  resourceAllocation: IResourceAllocationState;
  upload: IUploadState;
  subscribeForm: ISubscribeFormState;
  browserDataPermission: IBrowserDataPermissionState;
  termsAndConditions: ITermsAndConditionsState;
  faq: IFaqState;
}

const rootReducer = combineReducers<IAppState>({
  navigation: navigationReducer,
  facebook: facebookReducer,
  fileExplorer: fileExplorerReducer,
  resourceAllocation: resourceAllocationReducer,
  upload: uploadReducer,
  subscribeForm: subscribeFormReducer,
  browserDataPermission: browserDataPermissionReducer,
  termsAndConditions: termsAndConditionsReducer,
  faq: faqReducer
});

const sagaMiddleware = createSagaMiddleware();

const middlewareEnhancer = applyMiddleware(
  logger,
  sagaMiddleware
);

const composedEnhancers = composeWithDevTools(
  middlewareEnhancer,
  monitorReducerEnhancer
) as StoreEnhancer;

export function configureStore(): Store<IAppState, any> {
  const store = createStore(rootReducer, undefined, composedEnhancers);
  sagaMiddleware.run(rootSaga);

  return store;
}
