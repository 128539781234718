import React, { PureComponent } from 'react';
import { Typography, Grid } from '@material-ui/core';
import PricingCard from './PricingCard';
import { Link } from '../../../common/components';
import { archivalStorageCalculator, downloadCalculator } from '../../../common/helpers';

import './Pricing.scss';

const items = [
  {
    archivalStorageSize: `${archivalStorageCalculator.initialFreeArchivalStorageGb}GB`
  },
  {
    archivalStorageSize: '1TB'
  },
  {
    archivalStorageSize: '2TB',
    configurable: true
  }
];

export default class Pricing extends PureComponent<{}> {
  render() {
    return <div className="pricing">
      <Typography variant="body1">
        Once you've become an Archyvault user,
        you have {archivalStorageCalculator.initialFreeArchivalStorageGb}GB of Archival Storage
        and {downloadCalculator.initialFreeGb}GB/month of Free Download.
        You can extend or reduce your storage space anytime. <Link className="link" to="/faq">More</Link>
      </Typography>
      <Grid container spacing={2} className="cards-container">
        {
          items.map((item, index) =>
            <Grid key={index} item xs={12} sm={4}>
              <PricingCard {...item} />
            </Grid>)
        }
      </Grid>
      <Typography variant="body1">
        In case the monthly limit of Free Download is reached,
        you can get Extra Download for ${downloadCalculator.getCostForExtraDownload('1GB')}/Gb.
        Extra Download doesn't have an expiration period. <Link className="link" to="/faq">More</Link>.
      </Typography>
    </div>;
  }
}
