import React, { PureComponent } from 'react';
import { Button, List, ListItem, Slider } from '@material-ui/core';
import { GradientLine } from '../../../common/components';
import { archivalStorageCalculator, downloadCalculator, formatPrice } from '../../../common/helpers';

interface IOwnProps {
  archivalStorageSize: string;
  configurable?: boolean;
}

interface IState {
  archivalStorageSize: string;
}

const minArchivalStorageSize = 1;
const maxArchivalStorageSize = 100;

export default class PricingCard extends PureComponent<IOwnProps, IState> {
  constructor(props: IOwnProps) {
    super(props);
    const { archivalStorageSize } = props;

    this.state = {
      archivalStorageSize
    };

    this.onSliderChange = this.onSliderChange.bind(this);
  }

  render() {
    const { archivalStorageSize } = this.state;
    const { configurable } = this.props;

    const details = [
      `${archivalStorageSize} Archival Storage`,
      `${downloadCalculator.getFreeGb(archivalStorageSize)}GB/mo. Free Download`
    ];

    const cost = archivalStorageCalculator.getCost(archivalStorageSize);

    return <div className="pricing-card">
      <div className="pricing-card-content-wrapper">
        <div className="size">{archivalStorageSize}</div>
        <Slider
          style={{ visibility: configurable ? undefined : 'hidden' }}
          value={+archivalStorageSize.replace('TB', '')}
          min={minArchivalStorageSize}
          max={maxArchivalStorageSize}
          aria-labelledby="continuous-slider"
          onChange={this.onSliderChange}
        />
        <div className="price-value">
          {
            renderPrice(cost)
          }
        </div>
        <Button variant="outlined" color="primary" href="/register">register</Button>
        <GradientLine />
        <List>
          {
            details.map((item, index) => <ListItem key={index}>{item}</ListItem>)
          }
        </List>
      </div>
    </div>;
  }

  private onSliderChange(event: React.ChangeEvent<{}>, value: any) {
    this.setArchivalStorageSize(value);
  }

  private setArchivalStorageSize(tb: number) {
    const newSize = `${tb}TB`;

    if (newSize !== this.state.archivalStorageSize) {
      this.setState({
        ...this.state,
        archivalStorageSize: newSize
      });
    }
  }
}

function renderPrice(cost: number) {
  return cost === 0
    ? 'Free'
    : <>
      <span className="cost">${formatPrice(cost)}</span>
      <span className="period">/mo.</span>
    </>;
}
