import { getMatchedValueFromMap, parseSize } from './common';

class DownloadCalculator {
  readonly initialFreeGb = 2;

  private readonly freeGbForDownloadMap = {
    '1TB': 10
  };

  private readonly extraDownloadMap = {
    '1GB': 0.08
  };

  getFreeGb(archivalStorageSize: string) {
    const archivalStorageGb = parseSize(archivalStorageSize);

    if (archivalStorageGb < 1024) {
      return this.initialFreeGb;
    }

    const match = getMatchedValueFromMap(archivalStorageGb, this.freeGbForDownloadMap);
    return Math.trunc(archivalStorageGb / match.totalGb) * match.value;
  }

  getCostForExtraDownload(extraDownloadSize: string) {
    const extraDownloadGb = parseSize(extraDownloadSize);
    const match = getMatchedValueFromMap(extraDownloadGb, this.extraDownloadMap);

    return match.value;
  }
}

export default new DownloadCalculator();
