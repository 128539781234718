import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import MapIcon from '@material-ui/icons/Map';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import { faTag, faCamera } from '@fortawesome/free-solid-svg-icons';
import { Section, Footer, ScrollDownIndicator, FollowUs } from '../../common/components';
import Intro from './Intro';
import WorkResults from './WorkResults';
import Comparison from './Comparison';
import Roadmap from './Roadmap';
import Subscribe from './SubscribeForm';
import QuickTour from './QuickTour';
import Pricing from './Pricing';

import './Home.scss';

class Home extends PureComponent<{}> {
  render() {
    return <div className="home blog-page">
      {
        [
          {
            content: this.renderTitle(),
            className: 'title-section'
          },
          {
            icon: <CloudUploadIcon className="icon" />,
            title: 'Keep your data in safe place',
            content: <Intro />,
            id: 'intro'
          },
          {
            icon: <FontAwesomeIcon icon={faCamera} className="icon" />,
            title: 'Backup Your Work Results',
            content: <WorkResults />,
            id: 'work-results'
          },
          {
            icon: <InsertChartIcon className="icon" />,
            title: 'See the difference',
            content: <Comparison />,
            id: 'comparison'
          },
          {
            icon: <MenuBookIcon className="icon" />,
            title: 'Quick tour',
            content: <QuickTour />,
            id: 'quick-tour'
          },
          {
            icon: <FontAwesomeIcon icon={faTag} className="icon" />,
            title: 'Save on your storage costs',
            content: <Pricing />,
            id: 'pricing'
          },
          {
            icon: <MapIcon className="icon" />,
            title: 'Product roadmap',
            content: <Roadmap />,
            id: 'roadmap'
          },
          {
            icon: <NotificationsActiveIcon className="icon" />,
            title: 'Subscribe and follow us',
            subtitle: 'Stay in touch to know about the release date and our latest updated',
            content: this.renderSubscribeAndFollow(),
            id: 'subscribe-and-follow'
          }
        ].map((section, index) => <Section
          key={index}
          className={index % 2 === 0 && index !== 0 ? 'with-background' : undefined}
          {...section}
        />)
      }
      <Footer />
    </div>;
  }

  private renderTitle() {
    return <div>
      <video className="video-background" autoPlay muted loop>
        <source src="background.mp4" type="video/mp4" />
      </video>
      <img src="logo.svg" className="logo" alt="logo" />
      <Typography variant="h1">Welcome to Archyvault</Typography>
      <Typography variant="h6">The most advanced cloud storage in the world</Typography>
      <ScrollDownIndicator />
    </div>;
  }

  private renderSubscribeAndFollow() {
    return <div className="subscribe-and-follow">
      <Subscribe />
      <FollowUs />
    </div>;
  }
}

export default Home;
