import { IFileItem } from '../../components/MyFiles/FileExplorer/DirectoryView';

export function locate(
  target: Element | EventTarget,
  closestSelector: string,
  fileItems: IFileItem[]
): IFileItem | undefined {
  const fileItemNode = (target as Element).closest(closestSelector);

  if (fileItemNode) {
    return fileItems.find(r => r.ref && r.ref.current === fileItemNode);
  }
}

export function locateFileExplorerItem(
  target: Element | EventTarget | null | undefined,
  fileItems: IFileItem[]
): IFileItem | undefined {
  if (target) {
    return locate(target, '.file-explorer-item', fileItems);
  }
}
