export enum SubscribeFormActionTypes {
  SUBSCRIBE = 'SUBSCRIBE',
  SUBSCRIBE_VERIFIED_USER_BEHAVIOR = 'SUBSCRIBE_VERIFIED_USER_BEHAVIOR',
  SUBSCRIBE_SUCCEEDED = 'SUBSCRIBE_SUCCEEDED',
  SUBSCRIBE_FAILED = 'SUBSCRIBE_FAILED',
  ERASE_SUBSCRIBE_RESULT_MESSAGE = 'ERASE_SUBSCRIBE_RESULT_MESSAGE'
}

export interface ISubscribeAction {
  type: SubscribeFormActionTypes.SUBSCRIBE;
  payload: string;
}

export interface ISubscribeVerifiedUserBehaviorAction {
  type: SubscribeFormActionTypes.SUBSCRIBE_VERIFIED_USER_BEHAVIOR;
  payload: {
    recaptchaToken: string,
    data: string
  };
}

export interface ISubscribeSucceededAction {
  type: SubscribeFormActionTypes.SUBSCRIBE_SUCCEEDED;
}

export interface ISubscribeFailedAction {
  type: SubscribeFormActionTypes.SUBSCRIBE_FAILED;
  payload: string;
}

export interface IEraseSubscribeResultMessageAction {
  type: SubscribeFormActionTypes.ERASE_SUBSCRIBE_RESULT_MESSAGE;
}

export type SubscribeFormActions = ISubscribeAction
  | ISubscribeVerifiedUserBehaviorAction
  | ISubscribeSucceededAction
  | ISubscribeFailedAction
  | IEraseSubscribeResultMessageAction;

export const subscribe = (email: string): ISubscribeAction => ({
  type: SubscribeFormActionTypes.SUBSCRIBE,
  payload: email
});

export const subscribeSucceeded = (): ISubscribeSucceededAction => ({
  type: SubscribeFormActionTypes.SUBSCRIBE_SUCCEEDED
});

export const subscribeFailed = (message: string): ISubscribeFailedAction => ({
  type: SubscribeFormActionTypes.SUBSCRIBE_FAILED,
  payload: message
});

export const eraseSubscribeResultMessage = () => ({
  type: SubscribeFormActionTypes.ERASE_SUBSCRIBE_RESULT_MESSAGE
});
