import { Reducer } from 'redux';
import {
  BrowserDataPermissionActions,
  BrowserDataPermissionActionTypes
} from '../actions/browserDataPermissionActions';

export interface IBrowserDataPermissionState {
  readonly permitted?: boolean;
  readonly highlightCount: number;
}

const initialState: IBrowserDataPermissionState = {
  permitted: undefined,
  highlightCount: 0
};

export const browserDataPermissionReducer: Reducer<IBrowserDataPermissionState, BrowserDataPermissionActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case BrowserDataPermissionActionTypes.INIT_BROWSER_DATA_PERMISSION: {
      return {
        ...state,
        permitted: action.payload
      };
    }
    case BrowserDataPermissionActionTypes.SET_BROWSER_DATA_PERMISSION: {
      return {
        ...state,
        permitted: action.payload
      };
    }
    case BrowserDataPermissionActionTypes.HIGHLIGHT_BROWSER_DATA_PERMISSION_CONSENT: {
      return {
        ...state,
        highlightCount: state.highlightCount + 1
      };
    }
    default:
      return state;
  }
};
