export enum AnalyticsActionTypes {
  INIT_ANALYTICS = 'INIT_ANALYTICS',
}

export interface IInitAnalyticsAction {
  type: AnalyticsActionTypes.INIT_ANALYTICS;
}

export type AnalyticsActions = IInitAnalyticsAction;

export const init = (): IInitAnalyticsAction => ({
  type: AnalyticsActionTypes.INIT_ANALYTICS
});
