import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Breadcrumbs, Link, Typography } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import './Navigation.scss';

import { IAppState } from '../../../store';
import * as actions from '../../../actions/fileExplorerActions';
import { IPathFile } from '../../../reducers/fileExplorerReducer';

interface IOwnProps {
  path: IPathFile[];
}

interface IDispatchProps {
  goUp: (directory: IPathFile) => void;
  initFromUrl: () => void;
}

type IProps = IOwnProps & IDispatchProps;

class Navigation extends PureComponent<IProps> {
  componentDidMount() {
    window.onpopstate = () => this.props.initFromUrl();
  }

  render() {
    const { path, goUp } = this.props;
    const upDirectory = path.length > 1 ? path[path.length - 2] : undefined;

    return (
      <div className="navigation">
        <Link className="up-button" color="inherit" href={upDirectory ? upDirectory.navigationUrl : ''}
          onClick={e => {
            e.preventDefault();
            if (upDirectory) {
              goUp(upDirectory);
            }
          }}>
          <ArrowUpwardIcon fontSize="small" />
        </Link>
        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />} arial-label="navigation">
          {
            path.map((directory, index) => {
              if (index < path.length - 1) {
                return <Link key={directory.id} color="inherit" href={directory.navigationUrl}
                  onClick={e => {
                    e.preventDefault();
                    goUp(directory);
                  }}>
                  {directory.name}
                </Link>;
              }

              return <Typography key={directory.id} color="textPrimary">{directory.name}</Typography>;
            })
          }
        </Breadcrumbs>
      </div>
    );
  }
}

const mapStateToProps = (store: IAppState): IOwnProps => {
  return {
    path: store.fileExplorer.path
  };
};

const mapDispatchToProps: IDispatchProps = {
  goUp: actions.goUp,
  initFromUrl: actions.initFromUrl
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
