
import React, { PureComponent, ChangeEvent } from 'react';
import { connect } from 'react-redux';
import * as facebookActions from '../../actions/facebookActions';
import * as subscribeFromActions from '../../actions/subscribeFormActions';
import * as browserDataPermissionActions from '../../actions/browserDataPermissionActions';
import { IAppState } from '../../store';
import { Section, Link, Snackbar, Footer } from '../../common/components';
import { Typography, TextField, Grid } from '@material-ui/core';

import './Registration.scss';

interface IStateProps {
  facebookStatus?: string;
  facebookEmail?: string;
  facebookName?: string;
  storagePermitted: boolean;
  snackbarMessage?: string;
  subscribeSucceeded?: boolean;
}

interface IDispatchProps {
  subscribe: (email: string) => void;
  refreshFacebook: () => void;
  eraseSubscribeMessage: () => void;
  highlightBrowserDataPermissionConsent: () => void;
}

type IProps = IStateProps & IDispatchProps;

interface IState {
  promo: string;
}

class Registration extends PureComponent<IProps, IState> {
  constructor(props) {
    super(props);

    this.state = {
      promo: ''
    };

    this.onPromoChange = this.onPromoChange.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const { facebookStatus, facebookEmail, facebookName } = this.props;
    const { promo } = this.state;

    if ((prevProps.facebookStatus === 'not_authorized' || prevProps.facebookStatus === 'unknown')
      && facebookStatus === 'connected') {
      this.props.subscribe(JSON.stringify({
        email: facebookEmail,
        name: facebookName,
        promo
      }));
    }
  }

  render() {
    const { facebookStatus } = this.props;
    let title = '';

    if (facebookStatus === 'connected') {
      title = 'Welcome to Archyvault!';
    }

    if (facebookStatus === 'not_authorized' || facebookStatus === 'unknown') {
      title = 'Register as a new user';
    }

    return <div className="registration blog-page">
      {
        [
          {
            title,
            content: this.renderRegistration()
          }
        ].map((section, index) => <Section key={index} {...section} />)
      }
      <Footer />
    </div>;
  }

  componentDidMount() {
    this.setPromoFromUrl();
    this.props.refreshFacebook();

    if (!this.props.storagePermitted) {
      this.props.highlightBrowserDataPermissionConsent();
    }
  }

  private renderRegistration() {
    return <>
      {
        this.renderUnRegistered()
      }
      {
        this.renderRegistered()
      }
      {
        this.renderBrowserDataConsent()
      }
      {
        this.renderSnackbars()
      }
      <div id="fb-root"></div>
    </>;
  }

  private renderUnRegistered() {
    const { facebookStatus } = this.props;

    return <div className={
      facebookStatus !== 'not_authorized' && facebookStatus !== 'unknown'
        ? 'hidden'
        : undefined}
    >
      <Typography variant="body1">
        We are glad to see you are interested in Archyvault!
        We're sure your trip with Archyvault will be impressive!
      </Typography>
      <div className="facebook-registration-form">
        <Grid container spacing={2}>
          <Grid item sm xs={12}>
            <Typography variant="body1">
              If you have a promo code, please use it here:
          </Typography>
          </Grid>
          <Grid item sm xs={12}>
            <TextField
              label="Promo Code"
              variant="outlined"
              margin="dense"
              value={this.state.promo}
              onChange={this.onPromoChange}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item sm xs={12}>
            <Typography variant="body1">
              The simplest registration possible:
          </Typography>
          </Grid>
          <Grid item sm xs={12}>
            <div
              className="fb-login-button"
              data-width=""
              data-size="large"
              data-button-type="continue_with"
              data-auto-logout-link="false"
              data-scope="public_profile,email"
              data-use-continue-as="true">
            </div>
          </Grid>
        </Grid>
      </div>
    </div>;
  }

  private renderRegistered() {
    const { facebookStatus } = this.props;

    return <div className={facebookStatus !== 'connected' ? 'hidden' : undefined}>
      <Typography variant="body1">
        You have been successfully registered!
        We are going to release Archyvault Web Alfa at the end of October.
        Meanwhile, you might want to see some interesting information
        on our <Link className="link" to="/">Home Page</Link>,
        or try <Link className="link" to="/my-files">Demo</Link>.
      </Typography>
      <Typography variant="body1">
        See you soon!
      </Typography>
    </div>;
  }

  private renderBrowserDataConsent() {
    const { storagePermitted } = this.props;

    return <div className={storagePermitted ? 'hidden' : undefined}>
      <Typography variant="body1">
        In order to continue, please accept consent first.
        It is located on the bottom of the screen.
      </Typography>
    </div>;
  }

  private renderSnackbars() {
    const { subscribeSucceeded, snackbarMessage, } = this.props;
    return <>
      <Snackbar
        variant={'success'}
        open={subscribeSucceeded === true}
        message={snackbarMessage || ''}
        onClose={this.onSnackbarClose}
      />
      <Snackbar
        variant={'error'}
        open={subscribeSucceeded === false}
        message={snackbarMessage || ''}
        onClose={this.onSnackbarClose}
      />
    </>;
  }

  private setPromoFromUrl() {
    const parts = window.location.pathname.split('/');
    const lastPart = parts[parts.length - 1];
    const promo = lastPart !== 'register' ? lastPart : '';

    this.setPromo(promo);
  }

  private onSnackbarClose() {
    this.props.eraseSubscribeMessage();
  }

  private onPromoChange(event: ChangeEvent<HTMLInputElement>) {
    this.setPromo(event.target.value);
  }

  private setPromo(promo: string) {
    this.setState({
      ...this.state,
      promo
    });
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { status, email, name } = store.facebook;
  const { permitted } = store.browserDataPermission;

  const {
    subscribeSucceeded,
    subscribeResultMessage
  } = store.subscribeForm;

  return {
    facebookStatus: status,
    facebookEmail: email,
    facebookName: name,
    storagePermitted: Boolean(permitted),
    subscribeSucceeded,
    snackbarMessage: subscribeResultMessage
  };
};

const mapDispatchToProps: IDispatchProps = {
  subscribe: subscribeFromActions.subscribe,
  refreshFacebook: facebookActions.refresh,
  eraseSubscribeMessage: subscribeFromActions.eraseSubscribeResultMessage,
  highlightBrowserDataPermissionConsent: browserDataPermissionActions.highlightConsent
};

export default connect(mapStateToProps, mapDispatchToProps)(Registration);
