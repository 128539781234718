import { Reducer } from 'redux';
import _ from 'lodash';
import { default as uuid } from 'uuid/v1';

import {
  FileExplorerActions,
  FileExplorerActionTypes,
  StorageTypes
} from '../actions/fileExplorerActions';

import { moveForward, getCurrDirectory, replaceFileInPath, moveBackward } from '../common/helpers';

export enum FileTypes {
  DIRECTORY = 'DIRECTORY',
  IMAGE = 'IMAGE',
  MULTIPLE_FILES = 'MULTIPLE_FILES'
}

export interface IFile {
  readonly fileType: FileTypes;
  readonly name: string;
  readonly id: string;
  readonly storageType: StorageTypes;
  readonly url?: string;
}

export interface IDirectory extends IFile {
  readonly children: IFile[];
}

export interface IPathFile extends IFile {
  readonly navigationUrl: string;
}

export interface IPathDirectory extends IPathFile, IDirectory {
}

export interface IFileExplorerState {
  readonly selected: IFile[];
  readonly path: IPathFile[];
  readonly unresolvedPath: string[];
  readonly menuElement?: HTMLElement;
}

const rootDirectory: IDirectory = {
  fileType: FileTypes.DIRECTORY,
  id: 'my-files',
  name: 'my-files',
  storageType: StorageTypes.ARCHIVE,
  children: _.times(1000, i => i).map<IFile>(i => ({
    fileType: FileTypes.DIRECTORY,
    id: uuid(),
    name: `Folder ${i}`,
    storageType: getStorageType(i),
    children: [
      ..._.times(10, j => j).map<IFile>(j => ({
        fileType: FileTypes.DIRECTORY,
        id: uuid(),
        name: `Folder ${i}-${j}`,
        storageType: getStorageType(j),
        children: _.times(10, k => k).map<IFile>(k => ({
          fileType: FileTypes.IMAGE,
          id: uuid(),
          name: `${k}.jpg`,
          url: `${k}.jpg`,
          storageType: getStorageType(k)
        }))
      })),
      ..._.times(10, k => k).map<IFile>(k => ({
        fileType: FileTypes.IMAGE,
        id: uuid(),
        name: `${k}.jpg`,
        url: `${k}.jpg`,
        storageType: getStorageType(k)
      }))
    ]
  }))
};

function getStorageType(index: number) {
  const value = index % 10;
  return value === 3 || value === 8 || value === 9
    ? StorageTypes.FAST_ACCESS
    : StorageTypes.ARCHIVE;
}

const initialState: IFileExplorerState = {
  path: moveForward([], rootDirectory),
  selected: [rootDirectory],
  unresolvedPath: []
};

export const fileExplorerReducer: Reducer<IFileExplorerState, FileExplorerActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FileExplorerActionTypes.SELECT: {
      return {
        ...state,
        selected: action.payload && action.payload.length
          ? action.payload
          : [getCurrDirectory(state.path)]
      };
    }
    case FileExplorerActionTypes.TOGGLE_SELECTED: {
      let arr = getCurrDirectory(state.path).id === state.selected[0].id
        ? []
        : [...state.selected];

      const index = state.selected.findIndex(file => file.id === action.payload.id);

      if (index === -1) {
        arr = [...arr, action.payload];
      } else {
        arr.splice(index, 1);
      }

      return {
        ...state,
        selected: arr.length
          ? arr
          : [getCurrDirectory(state.path)]
      };
    }
    case FileExplorerActionTypes.OPEN: {
      return {
        ...state,
        path: moveForward(state.path, action.payload),
        selected: [action.payload]
      };
    }
    case FileExplorerActionTypes.OPEN_IN_PLACE: {
      return {
        ...state,
        path: replaceFileInPath(state.path, action.payload),
        selected: [action.payload]
      };
    }
    case FileExplorerActionTypes.GO_UP: {
      const path = moveBackward(state.path, action.payload);

      return {
        ...state,
        path,
        selected: [getCurrDirectory(path)],
        unresolvedPath: []
      };
    }
    case FileExplorerActionTypes.INIT_FROM_URL: {
      const folderNames = action.payload;
      let path: IPathFile[] = moveForward([], rootDirectory);
      let unresolvedPath: string[] = [];

      for (let i = 1; i < folderNames.length; i++) {
        const name = folderNames[i];
        const currFile = path[path.length - 1];

        if (currFile.fileType === FileTypes.IMAGE) {
          break;
        }

        const currDirectory = currFile as IPathDirectory;
        const child = currDirectory.children.find(c => c.name === name);

        if (child) {
          path = moveForward(path, child);
        } else {
          unresolvedPath = folderNames.slice(i);
          break;
        }
      }

      return {
        ...state,
        path,
        selected: [getCurrDirectory(path)],
        unresolvedPath
      };
    }
    case FileExplorerActionTypes.OPEN_CONTEXT_MENU: {
      return {
        ...state,
        menuElement: action.payload
      };
    }
    case FileExplorerActionTypes.CLOSE_CONTEXT_MENU: {
      return {
        ...state,
        menuElement: undefined
      };
    }
    case FileExplorerActionTypes.DROP_FILES: {
      const { currentDirectory, nextDirectory, files } = action.payload;

      _.remove(currentDirectory.children, item => files.find(file => file.id === item.id));

      const filesToAdd = files.map(file => {
        if (nextDirectory.children.find(item => item.name === file.name)) {
          const extIndex = _.lastIndexOf(file.name, '.');

          const name = file.name.substring(0, extIndex);
          const ext = file.name.substring(extIndex);

          return {
            ...file,
            name: `${name}-copy${ext}`
          };
        }

        return file;
      });

      nextDirectory.children.push(...filesToAdd);

      return {
        ...state,
        selected: [getCurrDirectory(state.path)]
      };
    }
    default:
      return state;
  }
};
