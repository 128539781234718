import { Reducer } from 'redux';
import { ResourceAllocationActions, ResourceAllocationActionTypes } from '../actions/resourceAllocationActions';

export interface IResourceAllocationState {
  readonly currentArchivalStorageBytes: number;
  readonly maxArchivalStorageBytes: number;
  readonly currentFreeMonthlyDownloadBytes: number;
  readonly maxFreeMonthlyDownloadBytes: number;
}

const defaultArchivalStorageBytes = 30 * 1024 * 1024 * 1024 * 8;
const defaultFreeMonthlyDownloadBytes = 2 * 1024 * 1024 * 1024 * 8;

const initialState: IResourceAllocationState = {
  currentArchivalStorageBytes: defaultArchivalStorageBytes * 0.58,
  maxArchivalStorageBytes: defaultArchivalStorageBytes,
  currentFreeMonthlyDownloadBytes: defaultFreeMonthlyDownloadBytes * 0.28,
  maxFreeMonthlyDownloadBytes: defaultFreeMonthlyDownloadBytes
};

export const resourceAllocationReducer: Reducer<IResourceAllocationState, ResourceAllocationActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ResourceAllocationActionTypes.RESET_MONTHLY_FREE_DOWNLOAD: {
      return {
        ...state,
        currentFreeMonthlyDownloadBytes: defaultFreeMonthlyDownloadBytes
      };
    }
    default:
      return state;
  }
};
