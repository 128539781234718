import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import SaveAlt from '@material-ui/icons/SaveAlt';
import './DropArea.scss';

import { IAppState } from '../../../store';

class DropArea extends PureComponent<{}> {
  render() {
    return (
      <div className="drop-area">
        <SaveAlt className="icon" />
        <span className="caption">Drop your files or folders</span>
      </div>);
  }
}

const mapStateToProps = (store: IAppState) => {
  return {
  };
};

export default connect(mapStateToProps)(DropArea);
