const classes = ['animated', 'shake'];
const eventName = 'animationend';

export function highlight(element: HTMLElement | null | undefined) {

  if (element) {
    element.classList.add(...classes);
    element.addEventListener(eventName, callback);
  }
}

function callback(event: Event) {
  const target = event.target as Element;

  if (target) {
    target.classList.remove(...classes);
    target.removeEventListener(eventName, callback);
  }
}
