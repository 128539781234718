
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import './FileExplorer.scss';

import { IAppState } from '../../../store';
import * as fileExplorerActions from '../../../actions/fileExplorerActions';
import * as navigationActions from '../../../actions/navigationActions';
import { IDirectory, IFile, FileTypes, IPathDirectory } from '../../../reducers/fileExplorerReducer';
import DirectoryView from './DirectoryView';
import SliderView from './SliderView';
import NotFound from '../../NotFound';

interface IStateProps {
  currentFile: IFile;
  parentDirectory: IDirectory;
  unresolvedPath: string[];
}

interface IDispatchProps {
  load: () => void;
  toggleAppBarComponents: (visible: boolean) => void;
}

type IProps = IStateProps & IDispatchProps;

class FileExplorer extends PureComponent<IProps> {
  componentDidMount() {
    const { load, toggleAppBarComponents } = this.props;

    load();
    toggleAppBarComponents(true);
  }

  componentWillUnmount() {
    const { toggleAppBarComponents } = this.props;
    toggleAppBarComponents(false);
  }

  render() {
    const { currentFile, parentDirectory, unresolvedPath } = this.props;
    return <div className="file-explorer">
      {
        (unresolvedPath.length
          && <NotFound message="Path is not found" path={`/${unresolvedPath.join('/')}`} />)
        || (currentFile.fileType === FileTypes.DIRECTORY
          && <DirectoryView currentDirectory={currentFile as IDirectory} />)
        || (currentFile.fileType === FileTypes.IMAGE
          && <SliderView file={currentFile} directory={parentDirectory} />)
      }
    </div>;
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { path, unresolvedPath } = store.fileExplorer;

  return {
    currentFile: path[path.length - 1],
    parentDirectory: path[path.length - 2] as IPathDirectory,
    unresolvedPath
  };
};

const mapDispatchToProps: IDispatchProps = {
  load: fileExplorerActions.initFromUrl,
  toggleAppBarComponents: navigationActions.toggleAppBarComponents
};

export default connect(mapStateToProps, mapDispatchToProps)(FileExplorer);
