export enum BrowserDataPermissionActionTypes {
  INIT_BROWSER_DATA_PERMISSION = 'INIT_BROWSER_DATA_PERMISSION',
  SET_BROWSER_DATA_PERMISSION = 'SET_BROWSER_DATA_PERMISSION',
  HIGHLIGHT_BROWSER_DATA_PERMISSION_CONSENT = 'HIGHLIGHT_BROWSER_DATA_PERMISSION_CONSENT'
}

export interface IInitBrowserDataPermissionAction {
  type: BrowserDataPermissionActionTypes.INIT_BROWSER_DATA_PERMISSION;
  payload: boolean;
}

export interface ISetBrowserDataPermissionAction {
  type: BrowserDataPermissionActionTypes.SET_BROWSER_DATA_PERMISSION;
  payload: boolean;
}

export interface IBrowserDataPermissionConsentAction {
  type: BrowserDataPermissionActionTypes.HIGHLIGHT_BROWSER_DATA_PERMISSION_CONSENT;
}

export type BrowserDataPermissionActions = IInitBrowserDataPermissionAction
  | ISetBrowserDataPermissionAction
  | IBrowserDataPermissionConsentAction;

export const init = (permitted: boolean): IInitBrowserDataPermissionAction => ({
  type: BrowserDataPermissionActionTypes.INIT_BROWSER_DATA_PERMISSION,
  payload: permitted
});

export const set = (permitted: boolean): ISetBrowserDataPermissionAction => ({
  type: BrowserDataPermissionActionTypes.SET_BROWSER_DATA_PERMISSION,
  payload: permitted
});

export const highlightConsent = (): IBrowserDataPermissionConsentAction => ({
  type: BrowserDataPermissionActionTypes.HIGHLIGHT_BROWSER_DATA_PERMISSION_CONSENT
});
