export interface IFacebookPayload {
  status: string;
  name?: string;
  email?: string;
}

export enum FacebookActionTypes {
  INIT_FACEBOOK = 'INIT_FACEBOOK',
  FACEBOOK_STATUS_CHANGED = 'FACEBOOK_STATUS_CHANGED',
  REFRESH_FACEBOOK = 'REFRESH_FACEBOOK',
  FACEBOOK_DISCONNECTED = 'FACEBOOK_FACEBOOK_DISCONNECTED'
}

export interface IInitFacebookAction {
  type: FacebookActionTypes.INIT_FACEBOOK;
}

export interface IFacebookStatusChangedAction {
  type: FacebookActionTypes.FACEBOOK_STATUS_CHANGED;
  payload: IFacebookPayload;
}

export interface IRefreshFacebookAction {
  type: FacebookActionTypes.REFRESH_FACEBOOK;
}

export interface IFacebookDisconnectedAction {
  type: FacebookActionTypes.FACEBOOK_DISCONNECTED;
}

export type FacebookActions = IInitFacebookAction
  | IFacebookStatusChangedAction
  | IRefreshFacebookAction
  | IFacebookDisconnectedAction;

export const init = (): IInitFacebookAction => ({
  type: FacebookActionTypes.INIT_FACEBOOK
});

export const changedStatus = (payload: IFacebookPayload): IFacebookStatusChangedAction => ({
  type: FacebookActionTypes.FACEBOOK_STATUS_CHANGED,
  payload
});

export const refresh = (): IRefreshFacebookAction => ({
  type: FacebookActionTypes.REFRESH_FACEBOOK
});

export const disconnected = (): IFacebookDisconnectedAction => ({
  type: FacebookActionTypes.FACEBOOK_DISCONNECTED
});
