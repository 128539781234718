import React, { PureComponent } from 'react';
import Link from '../Link';

import './ScrollDownIndicator.scss';

interface IState {
  visible: boolean;
}

export default class ScrollDownIndicator extends PureComponent<{}, IState> {
  constructor(props) {
    super(props);

    this.state = {
      visible: true
    };

    this.onScroll = this.onScroll.bind(this);
  }

  componentWillMount() {
    window.addEventListener('scroll', this.onScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScroll);
  }

  render() {
    return <Link to="/#intro"
      className="scroll-down-indicator"
      style={{ display: !this.state.visible ? 'none' : undefined }}
    >
      <span className="arrow"></span>
    </Link>;
  }

  private onScroll() {
    window.removeEventListener('scroll', this.onScroll);
    this.hide();
  }

  private hide() {
    this.setState({
      ...this.state,
      visible: false
    });
  }
}
