import { getCost } from './common';

class ArchivalStorage {
  readonly initialFreeArchivalStorageGb = 30;

  private readonly archivalStoragePriceMap = {
    '1TB': 2.99
  };

  getCost(size: string) {
    return getCost(
      size,
      this.initialFreeArchivalStorageGb,
      this.archivalStoragePriceMap
    );
  }
}

export default new ArchivalStorage();
