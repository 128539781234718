
import React from 'react';
import { connect } from 'react-redux';
import './FileExplorer.scss';

import { IAppState } from '../../../store';
import * as actions from '../../../actions/fileExplorerActions';
import { locateFileExplorerItem, isInRect } from '../../../common/helpers';
import { IFileItem } from './DirectoryView';
import FileExplorerItem from '../FileExplorerItem';
import { FileTypes, IFile, IDirectory } from '../../../reducers/fileExplorerReducer';
import Movable from './Movable';

interface IStateProps {
  selected: IFile[];
}

interface IOwnProps {
  fileItems: IFileItem[];
  currentDirectory: IDirectory;
}

interface IDispatchProps {
  select: (files: IFile[]) => void;
  dropFiles: (value: actions.IFileExplorerDroppedFiles) => void;
}

type IProps = IStateProps & IOwnProps & IDispatchProps;

class DnD extends Movable<IProps> {
  private selectedFileItems: IFileItem[] = [];
  private directoryOver?: IFileItem;

  render() {
    return <div className="dnd-container">
      {
        this.renderItem()
      }
    </div>;
  }

  protected onLongPress(event: MouseEvent) {
    super.onLongPress(event);

    const { fileItems, selected, select } = this.props;
    const curr = locateFileExplorerItem(event.target, fileItems);

    if (curr && this.self && this.parent) {
      this.self.classList.add('visible');
      this.parent.classList.add('hide-checkbox');

      this.setPosition();

      if (selected.find(file => curr.file.id === file.id)) {
        this.selectedFileItems = selected
          .map(file => fileItems
            .find(f => f.file === file)
          ) as IFileItem[];
        this.forceUpdate();
      } else {
        this.selectedFileItems = [curr];
        select([curr.file]);
      }
    }
  }

  protected onMouseMove(event: MouseEvent) {
    super.onMouseMove(event);

    if (this.isLongButtonPressed && this.x2 !== undefined && this.y2 !== undefined) {
      const { fileItems } = this.props;
      this.setPosition();

      const newDirectoryOver = fileItems.find(item => item.file.fileType === FileTypes.DIRECTORY
        && !this.selectedFileItems.find(selectedItem => selectedItem.file.id === item.file.id)
        && isInRect(
          { x: event.clientX, y: event.clientY },
          item.ref.current && item.ref.current.getBoundingClientRect())
      );

      if (newDirectoryOver !== this.directoryOver) {
        if (this.directoryOver && this.directoryOver.ref.current) {
          this.directoryOver.ref.current.classList.remove('drag-over');
        }

        if (newDirectoryOver && newDirectoryOver.ref.current) {
          newDirectoryOver.ref.current.classList.add('drag-over');
        }

        this.directoryOver = newDirectoryOver;
      }
    }
  }

  protected onMouseUp(event: MouseEvent) {
    super.onMouseUp(event);

    const { currentDirectory, dropFiles } = this.props;

    if (this.self && this.parent) {
      this.self.classList.remove('visible');
      this.parent.classList.remove('hide-checkbox');
    }

    if (this.directoryOver && this.directoryOver.ref.current) {
      this.directoryOver.ref.current.classList.remove('drag-over');
      dropFiles({
        currentDirectory,
        nextDirectory: this.directoryOver.file as IDirectory,
        files: this.selectedFileItems.map(item => item.file)
      });
    }

    this.directoryOver = undefined;
  }

  private setPosition() {
    if (this.isLongButtonPressed && this.self && this.parent
      && this.x2 !== undefined && this.y2 !== undefined
    ) {
      this.self.style.left = `${this.x2 - this.self.clientWidth * 0.6}px`;
      this.self.style.top = `${this.y2 - this.self.clientHeight * 0.8}px`;
    }
  }

  private renderItem() {
    if (this.selectedFileItems.length === 1) {
      const [fileItem] = this.selectedFileItems;
      return <FileExplorerItem file={fileItem.file} />;
    }

    return <FileExplorerItem
      file={{ fileType: FileTypes.MULTIPLE_FILES, id: 'd&d', name: 'd&d', storageType: actions.StorageTypes.ARCHIVE }}
    />;
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { selected } = store.fileExplorer;
  return {
    selected
  };
};

const mapDispatchToProps: IDispatchProps = {
  select: actions.select,
  dropFiles: actions.dropFiles
};

export default connect(mapStateToProps, mapDispatchToProps)(DnD);
