import React, { Component } from 'react';
import { Grid } from '@material-ui/core';
import FileExplorer from './FileExplorer';
import RightPanel from './RightPanel';
import Navigation from './Navigation';

import './MyFiles.scss';

class MyFiles extends Component {
  render() {
    return (
      <>
        <Grid item className="file-explorer-container">
          <Navigation />
          <FileExplorer />
        </Grid>
        <Grid item className="right-panel-container">
          <RightPanel />
        </Grid>
      </>
    );
  }
}

export default MyFiles;
