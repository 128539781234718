import React, { PureComponent } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import NotFound from './NotFound';
import Home from './Home';
import MyFiles from './MyFiles';
import HowItWorks from './HowItWorks';
import Registration from './Registration';
import Faq from './Faq';
import TermsAndConditions from './TermsAndConditions';
import Privacy from './Privacy';

import { AppBar, BrowserDataConsent } from '../common/components';

import './App.scss';

class App extends PureComponent<{}> {
  render() {
    return (
      <Router>
        <div className="app">
          <AppBar />
          <Grid container className="main">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/my-files" component={MyFiles} />
              <Route exact path="/my-files/*" component={MyFiles} />
              <Route exact path="/how-it-works" component={HowItWorks} />
              <Route exact path="/register" component={Registration} />
              <Route exact path="/register/*" component={Registration} />
              <Route exact path="/faq" component={Faq} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />
              <Route exact path="/privacy" component={Privacy} />
              <Route path="/not-found" component={NotFound} />
              <Route component={NotFound} />
            </Switch>
            <BrowserDataConsent />
          </Grid>
        </div>
      </Router>
    );
  }
}

export default App;
