import React, { PureComponent } from 'react';
import Button from '@material-ui/core/Button';
import PermMedia from '@material-ui/icons/PermMedia';

import './FileExplorerItem.scss';

import { IFile, FileTypes } from '../../../reducers/fileExplorerReducer';
import FileExplorerCheckbox from './FileExplorerCheckbox';
import FileExplorerDirectoryIcon from './FileExplorerDirectoryIcon';
import FileExplorerStorage from './FileExplorerStorage';

interface IOwnProps {
  file: IFile;
  isSelected?: boolean;
  innerRef?: React.RefObject<HTMLButtonElement>;
  isPlaceholderIsShown?: boolean;
  style?: any;
  menuElement?: HTMLElement;
}

type IProps = IOwnProps;

class FileExplorerItem extends PureComponent<IProps> {
  render() {
    const {
      innerRef, file, isPlaceholderIsShown, style, menuElement, isSelected
    } = this.props;
    return <Button
      buttonRef={innerRef}
      className={`${isSelected ? 'selected ' : ''}${isPlaceholderIsShown ? 'placeholder ' : ''}file-explorer-item`}
      aria-owns={menuElement ? 'context-menu' : undefined}
      aria-haspopup="true"
      style={style}>
      {
        !isPlaceholderIsShown &&
        renderContent(file, isSelected || false)
      }
    </Button>;
  }
}

function renderContent(file: IFile, isSelected: boolean) {
  return <>
    <FileExplorerCheckbox isSelected={isSelected} />
    <FileExplorerStorage storageType={file.storageType} />
    {renderElement(file)}
    <span className="caption">{file.name}</span>
  </>;
}

function renderElement(file: IFile) {
  switch (file.fileType) {
    case FileTypes.DIRECTORY:
      return <FileExplorerDirectoryIcon />;
    case FileTypes.IMAGE:
      return <img className="picture" src={`/images/${file.url}`} alt={file.name} draggable={false} />;
    case FileTypes.MULTIPLE_FILES:
      return <PermMedia className="icon" />;
    default:
      return <></>;
  }
}

export default FileExplorerItem;
