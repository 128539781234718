import axios from 'axios';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  FaqActionTypes,
  getSucceeded,
  getFailed
} from '../actions/faqActions';

const faqApi = () => {
  return axios.get('/content/en-us/faq.txt');
};

function* getFaq() {
  try {
    const { data } = yield call(faqApi);
    yield put(getSucceeded(data));
  } catch (err) {
    yield put(getFailed());
  }
}

export function* watchFaq() {
  yield all([
    takeEvery(FaqActionTypes.GET_FAQ, getFaq)
  ]);
}
