import { appendScript } from '.';
import { googleChartsUrl } from '..';

let google = (window as any).google;

const googlePromise = new Promise<any>(resolve => {
  if (google && google.charts) {
    resolve(google);
  } else {
    appendScript(googleChartsUrl,
      () => {
        google = (window as any).google;
        google.charts.load('current', { packages: ['corechart'] });
        google.charts.setOnLoadCallback(() => resolve(google));
      }
    );
  }
});

export async function drawColumnChart(container: HTMLElement, data: any[], options: object) {
  const chart = new (await googlePromise).visualization.ColumnChart(container);
  chart.draw(google.visualization.arrayToDataTable(data), options);
}

export async function drawPieChart(container: HTMLElement, data: any[], options: object) {
  const chart = new (await googlePromise).visualization.PieChart(container);
  chart.draw(google.visualization.arrayToDataTable(data), options);
}
