import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import FlipToFront from '@material-ui/icons/FlipToFront';
import FlipToBack from '@material-ui/icons/FlipToBack';
import HowToVote from '@material-ui/icons/HowToVote';
import Info from '@material-ui/icons/Info';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import Storage from '@material-ui/icons/Storage';
import FlashOn from '@material-ui/icons/FlashOn';
import { IAppState } from '../../../store';
import * as actions from '../../../actions/fileExplorerActions';

interface IStateProps {
  menuElement?: HTMLElement;
}

interface IDispatchProps {
  closeContextMenu: () => void;
}

type IProps = IStateProps & IDispatchProps;

class ContextMenu extends PureComponent<IProps> {
  render() {
    const { menuElement, closeContextMenu } = this.props;
    return <Menu
      id="context-menu"
      anchorEl={menuElement}
      open={Boolean(menuElement)}
      onClose={() => closeContextMenu()}
      onContextMenu={event => { event.preventDefault(); closeContextMenu(); }}
    >
      {
        [
          { name: 'Archive', icon: <Storage /> },
          { name: 'Fast Access', icon: <FlashOn /> },
          {},
          { name: 'Copy', icon: <FlipToFront /> },
          { name: 'Cut', icon: <FlipToBack /> },
          { name: 'Paste', icon: <HowToVote /> },
          {},
          { name: 'Delete', icon: <Delete /> },
          { name: 'Rename', icon: <Edit /> },
          { name: 'Info', icon: <Info /> },

        ].map(({ name, icon }, index) => {
          return name && icon
            ? <MenuItem key={name}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={name} />
            </MenuItem>
            : <Divider key={`divider-${index}`} />;
        })
      }
    </Menu>;
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { menuElement } = store.fileExplorer;
  return {
    menuElement
  };
};

const mapDispatchToProps: IDispatchProps = {
  closeContextMenu: actions.closeContextMenu
};

export default connect(mapStateToProps, mapDispatchToProps)(ContextMenu);
