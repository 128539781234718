import { getCurrentPathParts } from '../common/helpers';
import { IDirectory, IPathFile, IFile } from '../reducers/fileExplorerReducer';

export enum FileExplorerActionTypes {
  SELECT = 'SELECT',
  TOGGLE_SELECTED = 'TOGGLE_SELECTED',
  OPEN = 'OPEN',
  OPEN_IN_PLACE = 'OPEN_IN_PLACE',
  GO_UP = 'GO_UP',
  INIT_FROM_URL = 'INIT_FROM_URL',
  OPEN_CONTEXT_MENU = 'OPEN_CONTEXT_MENU',
  CLOSE_CONTEXT_MENU = 'CLOSE_CONTEXT_MENU',
  DROP_FILES = 'DROP_FILES'
}

export interface IFileExplorerSelectAction {
  type: FileExplorerActionTypes.SELECT;
  payload: IFile[];
}

export interface IFileExplorerToggleSelectedAction {
  type: FileExplorerActionTypes.TOGGLE_SELECTED;
  payload: IFile;
}

export interface IFileExplorerOpenAction {
  type: FileExplorerActionTypes.OPEN;
  payload: IFile;
}

export interface IFileExplorerOpenInPlaceAction {
  type: FileExplorerActionTypes.OPEN_IN_PLACE;
  payload: IFile;
}

export interface IFileExplorerGoUpAction {
  type: FileExplorerActionTypes.GO_UP;
  payload: IPathFile;
}

export interface IFileExplorerInitFromUrlAction {
  type: FileExplorerActionTypes.INIT_FROM_URL;
  payload: string[];
}

export interface IFileExplorerOpenContextMenuAction {
  type: FileExplorerActionTypes.OPEN_CONTEXT_MENU;
  payload?: HTMLElement;
}

export interface IFileExplorerCloseContextMenuAction {
  type: FileExplorerActionTypes.CLOSE_CONTEXT_MENU;
}

export interface IFileExplorerDropFileAction {
  type: FileExplorerActionTypes.DROP_FILES;
  payload: IFileExplorerDroppedFiles;
}

export interface IFileExplorerDroppedFiles {
  currentDirectory: IDirectory;
  nextDirectory: IDirectory;
  files: IFile[];
}

export enum StorageTypes {
  ARCHIVE = 'ARCHIVE',
  FAST_ACCESS = 'FAST_ACCESS'
}

export type FileExplorerActions = IFileExplorerSelectAction | IFileExplorerToggleSelectedAction
  | IFileExplorerOpenAction | IFileExplorerOpenInPlaceAction
  | IFileExplorerGoUpAction | IFileExplorerInitFromUrlAction
  | IFileExplorerOpenContextMenuAction | IFileExplorerCloseContextMenuAction
  | IFileExplorerDropFileAction;

export const select = (files: IFile[]): IFileExplorerSelectAction => ({
  type: FileExplorerActionTypes.SELECT,
  payload: files
});

export const toggleSelected = (file: IFile): IFileExplorerToggleSelectedAction => ({
  type: FileExplorerActionTypes.TOGGLE_SELECTED,
  payload: file
});

export const open = (file: IFile): IFileExplorerOpenAction => ({
  type: FileExplorerActionTypes.OPEN,
  payload: file
});

export const openInPlace = (file: IFile): IFileExplorerOpenInPlaceAction => ({
  type: FileExplorerActionTypes.OPEN_IN_PLACE,
  payload: file
});

export const goUp = (directory: IPathFile): IFileExplorerGoUpAction => ({
  type: FileExplorerActionTypes.GO_UP,
  payload: directory
});

export const initFromUrl = (): IFileExplorerInitFromUrlAction => ({
  type: FileExplorerActionTypes.INIT_FROM_URL,
  payload: getCurrentPathParts()
});

export const openContextMenu = (menuElement?: HTMLElement): IFileExplorerOpenContextMenuAction => ({
  type: FileExplorerActionTypes.OPEN_CONTEXT_MENU,
  payload: menuElement
});

export const closeContextMenu = (): IFileExplorerCloseContextMenuAction => ({
  type: FileExplorerActionTypes.CLOSE_CONTEXT_MENU
});

export const dropFiles = (payload: IFileExplorerDroppedFiles): IFileExplorerDropFileAction => ({
  type: FileExplorerActionTypes.DROP_FILES,
  payload
});
