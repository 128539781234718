import { Reducer } from 'redux';
import { FacebookActions, FacebookActionTypes } from '../actions/facebookActions';

export interface IFacebookState {
  readonly status?: string;
  readonly name?: string;
  readonly email?: string;
}

const initialState: IFacebookState = {
  status: undefined,
  name: undefined,
  email: undefined
};

export const facebookReducer: Reducer<IFacebookState, FacebookActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case FacebookActionTypes.FACEBOOK_STATUS_CHANGED: {
      const { status, name, email } = action.payload;
      return {
        ...state,
        status,
        name,
        email
      };
    }
    default:
      return state;
  }
};
