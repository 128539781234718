import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { faDropbox, faGoogleDrive } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwipeableViews from 'react-swipeable-views';
import { ColumnChart } from '../../../common/components';
import { archivalStorageCalculator } from '../../../common/helpers';

import './Comparison.scss';

interface IState {
  activeTab: number;
}

const tabs = [
  {
    label: 'vs Dropbox',
    icon: faDropbox,
    data: getDataForChart('Dropbox', [
      { size: '2TB', cost: 11.99 },
      { size: '3TB', cost: 19.99 }
    ])
  },
  {
    label: 'vs Google Drive',
    icon: faGoogleDrive,
    data: getDataForChart('Google Drive', [
      { size: '2TB', cost: 9.99 },
      { size: '10TB', cost: 99.99 },
      { size: '20TB', cost: 199.99 },
      { size: '30TB', cost: 299.99 }
    ])
  }
];

function getDataForChart(competitorName: string, prices: Array<{ size: string, cost: number }>) {
  return [
    ['', 'Archyvault', competitorName],
    ...prices.map(({ size, cost }) => [size, archivalStorageCalculator.getCost(size), cost])
  ];
}

export default class Comparison extends PureComponent<{}, IState> {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: 0
    };

    this.onTabChange = this.onTabChange.bind(this);
    this.onTabSwipe = this.onTabSwipe.bind(this);
  }

  render() {
    return <div className="comparison">
      <Typography variant="body1">
        Just compare with other popular cloud storages to see how awesome it is.
      </Typography>
      <Tabs value={this.state.activeTab}
        indicatorColor="primary"
        textColor="primary"
        centered
        onChange={this.onTabChange}
      >
        {
          tabs.map(({ label, icon }, index) =>
            <Tab key={index} label={label} icon={
              <FontAwesomeIcon className="icon" icon={icon} />
            } />
          )
        }
      </Tabs>
      <SwipeableViews index={this.state.activeTab} onChangeIndex={this.onTabSwipe}>
        {
          tabs.map(({ data }, index) =>
            <ColumnChart
              key={index}
              title="Estimated costs per month"
              subtitle="Lower is better"
              vAxisFormat="$##.##"
              series={{
                0: { color: '#306EAB' },
                1: { color: '#28B478' }
              }}
              data={data}
            />)
        }
      </SwipeableViews>
    </div>;
  }

  private onTabChange(event: React.ChangeEvent<{}>, activeTab: number) {
    this.setActiveTap(activeTab);
  }

  private onTabSwipe(activeTab: number) {
    this.setActiveTap(activeTab);
  }

  private setActiveTap(activeTab: number) {
    this.setState(
      {
        ...this.state,
        activeTab
      }
    );
  }
}
