export enum NavigationActionTypes {
  TOGGLE_DRAWER = 'TOGGLE_DRAWER',
  TOGGLE_APP_BAR_COMPONENTS = 'TOGGLE_APP_BAR_COMPONENTS'
}

export interface INavigationToggleDrawerAction {
  type: NavigationActionTypes.TOGGLE_DRAWER;
}

export interface INavigationToggleAppBarComponentsAction {
  type: NavigationActionTypes.TOGGLE_APP_BAR_COMPONENTS;
  payload: boolean;
}

export type NavigationActions = INavigationToggleDrawerAction | INavigationToggleAppBarComponentsAction;

export const toggleDrawer = () => ({
  type: NavigationActionTypes.TOGGLE_DRAWER
});

export const toggleAppBarComponents = (visible: boolean) => ({
  type: NavigationActionTypes.TOGGLE_APP_BAR_COMPONENTS,
  payload: visible
});
