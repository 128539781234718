// tslint:disable: no-console

export class Logger {
  static info(arg1?: any, arg2?: any) {
    console.info(arg1, arg2);
  }

  static group(name: string) {
    console.group(name);
  }

  static groupEnd() {
    console.groupEnd();
  }
}
