import { Logger } from '../common/helpers';

const logger = store => next => action => {
  if (!action.type) {
    return next(action);
  }

  Logger.group(action.type);
  Logger.info('dispatching', action);

  const result = next(action);

  Logger.info('next state', store.getState());
  Logger.groupEnd();

  return result;
};

export default logger;
