import { all, call, takeEvery, put } from 'redux-saga/effects';
import { RecaptchaActionTypes, init, IVerifyUserBehaviorAction } from '../actions/recaptchaActions';
import { recaptchaUrlTemplate, recaptchaKey } from '../common';
import {
  BrowserDataPermissionActionTypes,
  IInitBrowserDataPermissionAction,
  ISetBrowserDataPermissionAction
} from '../actions/browserDataPermissionActions';
import { appendScript } from '../common/helpers';

let resolveReCaptcha;
const reCaptchaPromise = new Promise<any>(resolve => resolveReCaptcha = resolve);

function appendReCapture() {
  appendScript(recaptchaUrlTemplate.replace('{key}', recaptchaKey),
    () => {
      const grecaptcha = (window as any).grecaptcha;
      grecaptcha.ready(() => resolveReCaptcha(grecaptcha));
    }
  );
}

function* initRecaptcha() {
  yield call(appendReCapture);
}

function* verifyBehavior(action: IVerifyUserBehaviorAction) {
  const { intension, nextActionType, nextActionPayload } = action.payload;

  const grecaptcha = yield reCaptchaPromise;
  const recaptchaToken = yield call(grecaptcha.execute, recaptchaKey, { action: intension });

  yield put({
    type: nextActionType,
    payload: {
      recaptchaToken,
      data: nextActionPayload
    }
  });
}

function* runRecaptcha(action: IInitBrowserDataPermissionAction | ISetBrowserDataPermissionAction) {
  const browserDataPermitted = action.payload;
  if (browserDataPermitted) {
    yield put(init());
  }
}

export function* watchRecaptcha() {
  yield all([
    takeEvery(RecaptchaActionTypes.INIT_RECAPTCHA, initRecaptcha),
    takeEvery(RecaptchaActionTypes.VERIFY_USER_BEHAVIOR, verifyBehavior),
    takeEvery(BrowserDataPermissionActionTypes.INIT_BROWSER_DATA_PERMISSION, runRecaptcha),
    takeEvery(BrowserDataPermissionActionTypes.SET_BROWSER_DATA_PERMISSION, runRecaptcha)
  ]);
}
