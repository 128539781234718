
import React, { PureComponent } from 'react';
import { Grid } from '@material-ui/core';
import CloudOff from '@material-ui/icons/CloudOff';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { connect } from 'react-redux';

import './NotFound.scss';
import { IAppState } from '../../store';

interface IOwnProps {
  message?: string;
  path?: string;
}

type IStateProps = IOwnProps;

class NotFound extends PureComponent<IStateProps> {
  render() {
    const { message, path } = this.props;
    return (
      <Grid item className="not-found">
        <CloudOff className="icon" />
        <h2>{message}</h2>
        <span>{path}</span>
      </Grid>
    );
  }
}

const mapStateToProps = (store: IAppState, ownProps: IOwnProps & RouteComponentProps): IStateProps => {
  return {
    message: ownProps.message || 'Page is not found',
    path: ownProps.path || ownProps.location.pathname
  };
};

export default withRouter(connect(mapStateToProps)(NotFound));
