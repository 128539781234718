export enum RecaptchaActionTypes {
  INIT_RECAPTCHA = 'INIT_RECAPTCHA',
  VERIFY_USER_BEHAVIOR = 'VERIFY_USER_BEHAVIOR'
}

export interface IVerifyUserBehaviorPayload {
  intension: string;
  nextActionType: string;
  nextActionPayload: any;
}

export interface IInitRecaptchaAction {
  type: RecaptchaActionTypes.INIT_RECAPTCHA;
}

export interface IVerifyUserBehaviorAction {
  type: RecaptchaActionTypes.VERIFY_USER_BEHAVIOR;
  payload: IVerifyUserBehaviorPayload;
}

export type RecaptchaActions = IInitRecaptchaAction | IVerifyUserBehaviorAction;

export const init = (): IInitRecaptchaAction => ({
  type: RecaptchaActionTypes.INIT_RECAPTCHA
});

export const verifyUserBehavior = (payload: IVerifyUserBehaviorPayload): IVerifyUserBehaviorAction => ({
  type: RecaptchaActionTypes.VERIFY_USER_BEHAVIOR,
  payload
});
