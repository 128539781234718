import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import { highlight } from '../../helpers';
import { IAppState } from '../../../store';
import * as actions from '../../../actions/browserDataPermissionActions';
import Link from '../Link';

import './BrowserDataConsent.scss';

interface IStateProps {
  permitted?: boolean;
  highlightCount: number;
}

interface IDispatchProps {
  setPermission: () => void;
}

type IProps = IStateProps & IDispatchProps;

class BrowserDataConsent extends PureComponent<IProps> {
  private selfRef?: HTMLElement | null;

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);
  }

  componentDidUpdate(prevProps: IProps) {
    const { highlightCount } = this.props;

    if (highlightCount !== prevProps.highlightCount) {
      highlight(this.selfRef);
    }
  }

  render() {
    const { permitted } = this.props;
    return <Snackbar
      innerRef={ref => this.selfRef = ref}
      className="browser-data-consent"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={permitted === false}
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span id="message-id">
          We would like to use cookies to enhance your experience and analyze statistics.
          Without cookies, some parts of the site
          won't work correctly. <Link className="link" to="/privacy">More</Link>.
        </span>
      }
      action={[
        <Button key="agree-and-close" size="small" variant="contained" onClick={this.onClick}>
          Agree
        </Button>
      ]}
    />;
  }

  private onClick() {
    this.props.setPermission();
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { permitted, highlightCount } = store.browserDataPermission;
  return {
    permitted,
    highlightCount
  };
};

const mapDispatchToProps: IDispatchProps = {
  setPermission: () => actions.set(true)
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowserDataConsent);
