import { call, all, takeEvery, put } from 'redux-saga/effects';
import { permitStorage, denyStorage, isStoragePermitted } from '../common/helpers';
import {
  BrowserDataPermissionActionTypes,
  IInitBrowserDataPermissionAction,
  ISetBrowserDataPermissionAction,
  init
} from '../actions/browserDataPermissionActions';

function* initBrowserDataPermission(action: IInitBrowserDataPermissionAction) {
  const storagePermitted = action.payload;
  yield call(storagePermitted ? permitStorage : denyStorage);
}

function* setBrowserDataPermission(action: ISetBrowserDataPermissionAction) {
  const storagePermitted = action.payload;
  yield call(storagePermitted ? permitStorage : denyStorage);
}

function* runBrowserDataPermission() {
  const permitted = isStoragePermitted();
  yield put(init(permitted));
}

export function* watchBrowserDataPermission() {
  yield all([
    takeEvery(BrowserDataPermissionActionTypes.INIT_BROWSER_DATA_PERMISSION, initBrowserDataPermission),
    takeEvery(BrowserDataPermissionActionTypes.SET_BROWSER_DATA_PERMISSION, setBrowserDataPermission),
    runBrowserDataPermission()
  ]);
}
