import { Reducer } from 'redux';
import { NavigationActions, NavigationActionTypes } from '../actions/navigationActions';

export interface INavigationState {
  readonly isDrawerOpen: boolean;
  readonly areComponentsShown: boolean;
}

const initialState: INavigationState = {
  isDrawerOpen: false,
  areComponentsShown: false
};

export const navigationReducer: Reducer<INavigationState, NavigationActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case NavigationActionTypes.TOGGLE_DRAWER: {
      return {
        ...state,
        isDrawerOpen: !state.isDrawerOpen
      };
    }
    case NavigationActionTypes.TOGGLE_APP_BAR_COMPONENTS: {
      return {
        ...state,
        areComponentsShown: action.payload
      };
    }
    default:
      return state;
  }
};
