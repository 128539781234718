import { PureComponent } from 'react';
import ReactDOM from 'react-dom';

abstract class Movable<TProps> extends PureComponent<TProps> {
  protected parent?: HTMLElement;
  protected self?: HTMLElement;

  protected x1?: number;
  protected y1?: number;

  protected x2?: number;
  protected y2?: number;

  protected isMoving = false;
  protected isButtonPressed = false;
  protected isLongButtonPressed = false;

  private readonly longPressDelay = 500;
  private timeout?: number;

  constructor(props) {
    super(props);

    this.onMouseDown = this.onMouseDown.bind(this);
    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onLongPress = this.onLongPress.bind(this);
    this.onContextMenu = this.onContextMenu.bind(this);
  }

  componentDidMount() {
    this.self = ReactDOM.findDOMNode(this) as HTMLElement;
    this.parent = this.self && this.self.parentNode as HTMLElement;

    if (this.parent) {
      this.parent.addEventListener('mousedown', this.onMouseDown);
      this.parent.addEventListener('mousemove', this.onMouseMove as any);
      this.parent.addEventListener('mouseup', this.onMouseUp);
      this.parent.addEventListener('contextmenu', this.onContextMenu);
    }
  }

  componentWillUnmount() {
    if (this.parent) {
      this.parent.removeEventListener('mousedown', this.onMouseDown);
      this.parent.removeEventListener('mousemove', this.onMouseMove as any);
      this.parent.removeEventListener('mouseup', this.onMouseUp);
      this.parent.removeEventListener('contextmenu', this.onContextMenu);
    }
  }

  protected onMouseDown(event: MouseEvent) {
    this.isButtonPressed = true;
    this.timeout = setTimeout(this.onLongPress, this.longPressDelay, event);
  }

  protected onLongPress(event: MouseEvent) {
    this.isButtonPressed = false;
    this.isLongButtonPressed = true;
  }

  protected onMouseMove(event: MouseEvent) {
    if (this.timeout && (event.movementX || event.movementY)) {
      clearTimeout(this.timeout);
    }

    if (this.isButtonPressed && this.parent
      && (event.movementX || event.movementY)
      && (this.x1 === undefined || this.y1 === undefined)) {

      this.isMoving = true;

      this.x1 = event.clientX - this.parent.offsetLeft;
      this.y1 = event.clientY - this.parent.offsetTop + document.documentElement.scrollTop;
    }

    if (this.parent) {
      this.x2 = event.clientX - this.parent.offsetLeft;
      this.y2 = event.clientY - this.parent.offsetTop + document.documentElement.scrollTop;
    }
  }

  protected onMouseUp(event: MouseEvent) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    this.isMoving = false;
    this.isButtonPressed = false;
    this.isLongButtonPressed = false;

    this.x1 = undefined;
    this.y1 = undefined;
  }

  protected onContextMenu() {
    clearTimeout(this.timeout);

    this.isButtonPressed = false;
    this.isLongButtonPressed = false;
  }

}

export default Movable;
