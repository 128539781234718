import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from '../../../common/components';

import './WorkResults.scss';
import { archivalStorageCalculator } from '../../../common/helpers';

export default class WorkResults extends PureComponent<{}> {
  render() {
    return <div className="work-results">
      <Typography variant="body1">
        Any physical data storage could be damaged, lost, or stolen.
        It is vital to have a reliable backup of your most valuable files.
        Just imagine, every terabyte of your professional movie clips or images
        could be stored reliably in the cloud and protected
        by your password. <Link className="link" to="/register">
        Start with {archivalStorageCalculator.initialFreeArchivalStorageGb}GB of
        Archive Storage for Free</Link> or
        read <Link className="link" to="/how-it-works">more</Link>.
      </Typography>
      <img className="media-software"
        src="/images/media-software.png"
        alt="media software"
      />
    </div>;
  }
}
