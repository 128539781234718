export function appendScript(url: string, onLoad?: () => void) {
  const script = document.createElement('script');

  script.src = url;
  script.async = true;

  if (onLoad) {
    script.onload = onLoad;
  }

  document.body.appendChild(script);
}
