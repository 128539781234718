
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import HelpIcon from '@material-ui/icons/Help';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import * as actions from '../../actions/faqActions';
import { Section, Footer } from '../../common/components';
import { IAppState } from '../../store';

import './Faq.scss';
import { Typography } from '@material-ui/core';

interface IStateProps {
  questionAnswerList: Array<{ question: string, answer: string[] }>;
}

interface IDispatchProps {
  get: () => void;
}

type IProps = IStateProps & IDispatchProps;

class Faq extends PureComponent<IProps> {
  componentWillMount() {
    this.props.get();
  }

  render() {
    const { questionAnswerList } = this.props;

    return <div className="faq blog-page">
      <Section
        icon={<HelpIcon className="icon" />}
        title="Frequently asked questions"
        content={
          <ol className="faq-list">
            {
              questionAnswerList.map(({ question, answer }, index) => {
                const arr: Array<{ type?: 'inner-list', value?: string, values?: string[] }> = [];

                answer.forEach(line => {
                  if (line.startsWith('- ')) {
                    const last = arr[arr.length - 1];
                    let list;

                    if (last && last.type === 'inner-list' && last.values) {
                      list = last;
                    } else {
                      list = { type: 'inner-list', values: [] };
                      arr.push(list);
                    }

                    list.values.push(line.replace('- ', ''));
                  } else {
                    arr.push({ value: line });
                  }
                });

                return <li key={index} className="faq-list-item">
                  <Typography variant="body1">{question}</Typography>
                  {
                    arr.map((item, innerIndex) => {
                      switch (item.type) {
                        case 'inner-list':
                          return <ul key={`${index}-${innerIndex}`} className="inner-list">
                            {
                              item.values && item.values.map((value, innerListIndex) =>
                                <li key={`${index}-${innerIndex}-${innerListIndex}`} className="inner-list-item">
                                  <div>
                                    <ArrowForwardIosIcon className="icon" />
                                  </div>
                                  <Typography variant="body2">{value}</Typography>
                                </li>
                              )
                            }
                          </ul>;
                        default:
                          return <Typography key={`${index}-${innerIndex}`} variant="body2">{item.value}</Typography>;
                      }
                    })
                  }
                </li>;
              }
              )
            }
          </ol>
        }
      />
      <Footer />
    </div>;
  }
}

const mapStateToProps = (store: IAppState): IStateProps => {
  const { questionAnswerList } = store.faq;

  return {
    questionAnswerList
  };
};

const mapDispatchToProps: IDispatchProps = {
  get: actions.get
};

export default connect(mapStateToProps, mapDispatchToProps)(Faq);
