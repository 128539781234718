import { Reducer } from 'redux';
import {
  TermsAndConditionsActions,
  TermsAndConditionsActionTypes
} from '../actions/termsAndConditionsActions';
import { convertTextToSections, readLine } from '../common/helpers';

export interface ITermsAndConditionsState {
  readonly dateStr: string;
  readonly sections: Array<{ title: string, text: string[] }>;
}

const initialState: ITermsAndConditionsState = {
  dateStr: '',
  sections: []
};

export const termsAndConditionsReducer: Reducer<ITermsAndConditionsState, TermsAndConditionsActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS_SUCCEEDED: {
      return {
        ...state,
        dateStr: readLine(action.payload, 0),
        sections: convertTextToSections(action.payload)
      };
    }
    default:
      return state;
  }
};
