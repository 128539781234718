import Chart, * as chart from './Chart';
import { drawColumnChart } from '../../helpers';

interface IAxe {
  [index: number]: { label: string };
}

interface IOwnProps extends chart.IOwnProps {
  stacked?: boolean;
  vAxisFormat: string;
  axes?: {
    x?: IAxe,
    y?: IAxe
  };
}

class ColumnChart extends Chart<IOwnProps> {
  protected async drawChart() {
    const { data, series, stacked, vAxisFormat, axes } = this.props;

    const options = {
      ...this.createInitialChartOptions(series),
      stacked,
      // version 46+
      // series,
      vAxis: {
        format: vAxisFormat,
        // classic
        minorGridlines: {
          count: 0
        },
        gridlines: {
          color: '#ddd'
        },
        baselineColor: '#ddd',
        textStyle: {
          color: '#888',
          fontSize: 12
        }
      },
      // classic
      hAxis: {
        textStyle: {
          color: '#888',
          fontSize: 12
        }
      },
      axes
    };

    if (this.selfRef) {
      await drawColumnChart(this.selfRef, data, options);
    }
  }
}

export default ColumnChart;
