import React, { PureComponent } from 'react';
import { HashLink, HashLinkProps } from 'react-router-hash-link';

class Link extends PureComponent<HashLinkProps> {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  render() {
    return <HashLink {...this.props} onClick={this.onClick} />;
  }

  private onClick(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    const { onClick } = this.props;

    if (onClick) {
      onClick(event);
    }

    window.scrollTo(0, 0);
  }
}

export default Link;
