import { IPathFile, IFile } from '../../reducers/fileExplorerReducer';

export function moveForward(path: IPathFile[], file: IFile): IPathFile[] {
  const url = path.length
    ? `${path[path.length - 1].navigationUrl}/${file.name}`
    : `${window.location.origin}/${file.name}`;

  return [
    ...path,
    {
      ...file,
      navigationUrl: convertToFriendlyUrl(url)
    }
  ];
}

export function moveBackward(path: IPathFile[], directory: IPathFile): IPathFile[] {
  const index = path.findIndex(d => d.id === directory.id);
  return path.slice(0, index + 1);
}

export function replaceFileInPath(path: IPathFile[], file: IFile): IPathFile[] {
  const url = path.length
    ? `${path[path.length - 1].navigationUrl}/${file.name}`
    : `${window.location.origin}/${file.name}`;

  return [
    ...path.slice(0, path.length - 1),
    {
      ...file,
      navigationUrl: convertToFriendlyUrl(url)
    }
  ];
}

export function getCurrentPathParts(): string[] {
  const { pathname } = window.location;
  return pathname.split('/').filter(s => s.length).map(s => convertFromFriendlyUrl(s));
}

export const getCurrDirectory = (path: IFile[]) => path[path.length - 1];

export function extendUrl(file: IFile) {
  const parts = [window.origin, ...getCurrentPathParts(), file.name];
  const url = convertToFriendlyUrl(parts.join('/'));

  window.history.pushState(null, file.name, url);
}

export function updateUrl(directory: IPathFile) {
  window.history.pushState(null, directory.name, directory.navigationUrl);
}

export function updateFileInUrl(file: IFile) {
  const arr = getCurrentPathParts();
  arr.pop();

  const parts = [window.origin, ...arr, file.name];
  const url = convertToFriendlyUrl(parts.join('/'));

  window.history.replaceState(null, file.name, url);
}

export function convertToFriendlyUrl(value: string) {
  return value.replace(/ /g, '+');
}

export function convertFromFriendlyUrl(value: string) {
  return value.replace(/\+/g, ' ');
}
