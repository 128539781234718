export enum FaqActionTypes {
  GET_FAQ = 'GET_FAQ',
  GET_FAQ_SUCCEEDED = 'GET_FAQ_SUCCEEDED',
  GET_FAQ_FAILED = 'GET_FAQ_FAILED'
}

export interface IFaqGetAction {
  type: FaqActionTypes.GET_FAQ;
}

export interface IFaqGetSucceededAction {
  type: FaqActionTypes.GET_FAQ_SUCCEEDED;
  payload: string;
}

export interface IFaqGetFailedAction {
  type: FaqActionTypes.GET_FAQ_FAILED;
}

export type FaqActions = IFaqGetAction
  | IFaqGetSucceededAction
  | IFaqGetFailedAction;

export const get = (): IFaqGetAction => ({
  type: FaqActionTypes.GET_FAQ
});

export const getSucceeded = (text: string): IFaqGetSucceededAction => ({
  type: FaqActionTypes.GET_FAQ_SUCCEEDED,
  payload: text
});

export const getFailed = (): IFaqGetFailedAction => ({
  type: FaqActionTypes.GET_FAQ_FAILED
});
