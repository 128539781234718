import React, { FunctionComponent } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Store } from 'redux';
import { createMuiTheme } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import { configureStore, IAppState } from './store';
import App from './components/App';

import './index.scss';

import * as serviceWorker from './serviceWorker';

interface IProps {
  store: Store<IAppState>;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#306EAB'
    },
    secondary: {
      main: '#FFAE39'
    }
  }
},
);

const Root: FunctionComponent<IProps> = props => {
  return (
    <MuiThemeProvider theme={theme}>
      <Provider store={props.store}>
        <App />
      </Provider>
    </MuiThemeProvider>
  );
};

const store = configureStore();
const rootElement = document.getElementById('root');

if (rootElement) {
  if (rootElement.hasChildNodes()) {
    ReactDOM.hydrate(<Root store={store} />, rootElement);
  } else {
    ReactDOM.render(<Root store={store} />, rootElement);
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
