import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import VerifiedUser from '@material-ui/icons/VerifiedUser';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGoogle, faAws } from '@fortawesome/free-brands-svg-icons';
import { Section, Footer } from '../../common/components';

class Privacy extends PureComponent<{}> {
  render() {
    return <div className="privacy blog-page">
      {
        [
          {
            className: 'title-section',
            content: <Typography variant="h1">Privacy Policy</Typography>
          },
          {
            icon: <VerifiedUser className="icon" />,
            title: 'Your data privacy is #1 priority for us',
            content: <>
              <Typography variant="body1">
                At the current moment, we invite everyone who might be interested in our services.
                For this purpose, we kindly ask you to provide your email,
                so later we could send our newsletters to you, or ask for your feedback.
                The only Personal Data, which might be stored on our servers so far, is your email and name.
                We will never share it with third-party companies.
                If you decide that you no longer want to receive communication from us
                and store your email on our servers, please send a message request
                  to <a href="mailto:support@archyvault.com" className="link"
                  target="_blank" rel="noopener noreferrer">support@archyvault.com</a>.
                </Typography>
              <Typography variant="body1">
                This Privacy Policy might be updated once we release new features,
                so we may ask you to read the updated policy and confirm your agreement.
                </Typography>
            </>
          },
          {
            icon: <FontAwesomeIcon className="icon" icon={faGoogle} />,
            title: 'Google Services',
            className: 'with-background',
            content: <>
              <Typography variant="body1">
                We use 2 Google services: reCAPTCHA v3 and Analytics.
                </Typography>
              <Typography variant="body1">
                <a href="https://www.google.com/recaptcha/intro/v3.html" className="link"
                  target="_blank" rel="noopener noreferrer">reCAPTCHA</a> prevents
           malicious actions from bots.
          We use the 3rd (latest) version, which scores user interactions with the website.
          Low score means it is likely a bot.
          Scoring behavior is advanced technic, which allows getting rid of checkbox and picture verification.
                </Typography>
              <Typography variant="body1">
                <a href="https://marketingplatform.google.com/about/analytics" className="link"
                  target="_blank" rel="noopener noreferrer">Google Analytics</a> helps us
              to understand our users without disclosing their Personal Data.
              For instance, we could know the geographical region,
              where our services are the most popular, age, and gender groups,
              but we would never know addresses, names, or emails of our users.
              This knowledge is crucial to provide the best service for our users.
                </Typography>
              <Typography variant="body1">
                reCAPTCHA and Analytics use Cookies and Local Storage.
                Cookies are small pieces of information, which allow servers
                to send relevant information for browsers.
                Local Storage is used to store a small amount of data in a browser.
                Before using Cookies and Local Storage, we ask your permission to do that.
                </Typography>
              <Typography variant="body1">
                More about Google <a className="link"
                  href="https://policies.google.com/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Privacy Policy
                  </a>, <a className="link"
                  href="https://safety.google/privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Safety
                  </a>, and <a className="link"
                  href="https://policies.google.com/terms"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Terms of Service
                  </a>
              </Typography>
            </>
          },
          {
            icon: <FontAwesomeIcon className="icon" icon={faAws} />,
            title: 'Amazon Web Services',
            content: <Typography variant="body1">
              We use <a href="https://aws.amazon.com" className="link"
                target="_blank" rel="noopener noreferrer">Amazon Web Services</a> to provide our services.
            All infrastructure, including service endpoints and databases, lives there.
            AWS is the biggest Computing Cloud Provider.
            All your data is transferred via encrypted channels and stored in secure databases.
              </Typography>
          }
        ].map((section, index) => <Section key={index} {...section} />)
      }
      <Footer />
    </div>;
  }
}

export default Privacy;
