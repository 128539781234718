export function getCost(
  size: string,
  freeGb: number,
  priceMap: { [key: string]: number }
) {
  const gb = parseSize(size);
  const paidGb = gb > freeGb ? gb : 0;

  return paidGb * getPricePerGb(paidGb, priceMap);
}

function getPricePerGb(size: number, priceMap: { [key: string]: number }) {
  const match = getMatchedValueFromMap(size, priceMap);
  return match.value / match.totalGb;
}

export function getMatchedValueFromMap(size: number, priceMap: { [key: string]: number }) {
  const arr = Object.keys(priceMap).map(key => {
    const totalGb = parseSize(key);
    if (totalGb) {
      return {
        totalGb,
        value: priceMap[key]
      };
    }

    throw new Error('Regex failed');
  })
    .sort((prev, next) => prev.totalGb < next.totalGb ? -1 : 1);

  return arr.find(type => size >= type.totalGb) || arr[arr.length - 1];
}

export function parseSize(str: string) {
  const regex = /^(?<value>[0-9]+?(\.[0-9]+)?)(?<units>TB|GB)$/;
  const result = regex.exec(str);

  if (result) {
    const { value, units } = result.groups as any;
    return +value * (units === 'TB' ? 1024 : 1);
  }

  throw new Error(`Failed to parse size: ${str}`);
}
