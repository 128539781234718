import { Reducer } from 'redux';
import { SubscribeFormActions, SubscribeFormActionTypes } from '../actions/subscribeFormActions';

export interface ISubscribeFormState {
  readonly subscribeSucceeded?: boolean;
  readonly subscribeResultMessage?: string;
  readonly loading: boolean;
}

const initialState: ISubscribeFormState = {
  subscribeSucceeded: undefined,
  subscribeResultMessage: undefined,
  loading: false
};

export const subscribeFormReducer: Reducer<ISubscribeFormState, SubscribeFormActions> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case SubscribeFormActionTypes.SUBSCRIBE: {
      return {
        ...state,
        loading: true
      };
    }
    case SubscribeFormActionTypes.SUBSCRIBE_SUCCEEDED: {
      return {
        ...state,
        subscribeSucceeded: true,
        subscribeResultMessage: 'You have successfully subscribed to our newsletter',
        loading: false
      };
    }
    case SubscribeFormActionTypes.SUBSCRIBE_FAILED: {
      return {
        ...state,
        subscribeSucceeded: false,
        subscribeResultMessage: action.payload,
        loading: false
      };
    }
    case SubscribeFormActionTypes.ERASE_SUBSCRIBE_RESULT_MESSAGE: {
      return {
        ...state,
        subscribeSucceeded: undefined,
        subscribeResultMessage: undefined
      };
    }
    default:
      return state;
  }
};
