import React from 'react';
import { Container, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { formatTitle, findNumeratedList, findOwnHttpAddress } from '../../helpers';

import './Section.scss';
import Link from '../Link';

interface ISectionItemPart {
  type?: 'own-http-address';
  text: string;
}

interface ISectionItem {
  type?: 'subtitle' | 'numbered-list' | 'own-http-address';
  parts: ISectionItemPart[];
}

export default function Section(
  { icon, title, subtitle, content, text, id, className }
    : {
      icon?: JSX.Element,
      title?: string,
      subtitle?: string,
      content?: JSX.Element,
      text?: string[],
      id?: string,
      className?: string
    },
  key: number
) {
  if (text) {
    const sectionItems = process(text);
    content = <>
      {
        sectionItems.map((sectionItem, index) => {
          switch (sectionItem.type) {
            case 'numbered-list': return <ol key={index} className="MuiTypography-root MuiTypography-body1 list">
              {
                sectionItem.parts.map((listItem, listItemIndex) =>
                  <li key={`${index}-${listItemIndex}`} className="list-item">{listItem.text}</li>)
              }
            </ol>;
            case 'subtitle': return <Typography key={index} variant="h6">{sectionItem.parts[0].text}</Typography>;
            default: return <Typography key={index} variant="body1">{
              sectionItem.parts.map((part, partIndex) => {
                switch (part.type) {
                  case 'own-http-address':
                    return <Link key={partIndex} to={part.text.replace('https://archyvault.com', '')} className="link">{
                      part.text
                    }</Link>;
                  default: return part.text;
                }
              })
            }</Typography>;
          }
        })
      }
    </>;
  }

  return <section key={key} id={id} className={clsx('section', className)}>
    <Container maxWidth="md">
      {icon}
      {title && <Typography variant="h2">{formatTitle(title)}</Typography>}
      {subtitle && <Typography variant="h6">{subtitle}</Typography>}
      {content}
    </Container>
  </section>;
}

function process(text: string[]): ISectionItem[] {
  let result = convertToSectionItems(text);

  result = processLists(result);
  processSubtitles(result);
  processOwnHttpAddresses(result);

  return result;
}

function convertToSectionItems(text: string[]): ISectionItem[] {
  return text.map(line => ({ parts: [{ text: line }] }));
}

function processLists(sectionItems: ISectionItem[]): ISectionItem[] {
  const listPosition = findNumeratedList(sectionItems.map(item => item.parts[0].text));

  if (listPosition) {
    const listItems: string[] = [];

    for (let i = listPosition.start; i <= listPosition.end; i++) {
      listItems.push(sectionItems[i].parts[0].text.replace(/^\d+\.\s+/, ''));
    }

    const result: ISectionItem[] = [
      ...sectionItems.slice(0, listPosition.start + 1),
      ...sectionItems.slice(listPosition.end + 1, sectionItems.length)
    ];

    result[listPosition.start] = {
      type: 'numbered-list',
      parts: listItems.map(item => ({ text: item }))
    };

    return result;
  }

  return sectionItems;
}

function processSubtitles(sectionItems: ISectionItem[]) {
  sectionItems.forEach(item => {
    const text = item.parts[0].text;
    if (item.parts.length === 1 && text.length < 50 && text.indexOf(':') === -1 && text.indexOf('@') === -1) {
      item.type = 'subtitle';
    }
  });
}

function processOwnHttpAddresses(sectionItems: ISectionItem[]) {
  sectionItems.forEach(item => {
    const matches: Array<{ partIndex: number, start: number, value: string }> = [];

    item.parts.forEach((part, index) => {
      const ownHttpAddresses = findOwnHttpAddress(part.text);

      if (ownHttpAddresses) {
        ownHttpAddresses.forEach(({ start, value }) => {
          matches.push({ partIndex: index, start, value });
        });
      }
    });

    let partIndexDisplacement = 0;
    let strDisplacement = 0;

    matches.slice(0, 2).forEach(match => {
      const currPartIndex = partIndexDisplacement + match.partIndex;
      const currText = item.parts[currPartIndex].text;

      const newParts: ISectionItemPart[] = [
        { text: currText.substring(0, match.start - strDisplacement) },
        { type: 'own-http-address', text: match.value },
        { text: currText.substring(match.start + match.value.length - strDisplacement) }
      ];

      item.parts.splice(currPartIndex, 1, ...newParts);

      partIndexDisplacement += 2;
      strDisplacement += newParts[0].text.length + newParts[1].text.length;
    });

    return item;
  });
}
