import { all } from 'redux-saga/effects';
import { watchBrowserDataPermission } from './browserDataPermissionSaga';
import { watchTermsAndConditions } from './termsAndConditionsSaga';
import { watchFaq } from './faqSaga';
import { watchFileExplorer } from './fileExplorerSaga';
import { watchSubscribeForm } from './subscribeFormSaga';
import { watchRecaptcha } from './recaptchaSaga';
import { watchAnalytics } from './analyticsSaga';
import { watchFacebook } from './facebookSaga';

export default function* rootSaga() {
  yield all([
    watchFileExplorer(),
    watchTermsAndConditions(),
    watchFaq(),
    watchSubscribeForm(),
    watchBrowserDataPermission(),
    watchRecaptcha(),
    watchAnalytics(),
    watchFacebook()
  ]);
}
