import React, { PureComponent } from 'react';
import { Stepper, Step, StepLabel, Typography, List, ListItem, ListItemIcon, Grid } from '@material-ui/core';

import './Roadmap.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChrome,
  faSafari,
  faFirefox,
  faEdge,
  faApple,
  faAndroid,
  faWindows,
  faOpera,
  faUbuntu,
  faFedora,
  faSuse,
  faCentos,
  IconDefinition
} from '@fortawesome/free-brands-svg-icons';

class Roadmap extends PureComponent<{}> {
  private products = [
    {
      name: 'browser',
      icons: [faChrome, faSafari, faFirefox, faEdge, faOpera],
      releases: [
        {
          label: 'November 2019',
          content: 'Archyvault Web Alfa'
        },
        {
          label: 'December 2019',
          content: 'Archyvault Web Beta'
        },
        {
          label: 'February 2020',
          content: 'Archyvault Web Release 1.0'
        },
      ]
    },
    {
      name: 'mobile',
      icons: [faApple, faAndroid],
      releases: [
        {
          label: 'March 2020',
          content: 'Archyvault Mobile Alfa'
        },
        {
          label: 'April 2020',
          content: 'Archyvault Mobile Beta'
        },
        {
          label: 'May 2020',
          content: 'Archyvault Mobile Release 1.0'
        },
      ]
    },
    {
      name: 'desktop',
      icons: [faApple, faWindows, faUbuntu, faFedora, faSuse, faCentos],
      releases: [
        {
          label: 'June 2020',
          content: 'Archyvault Desktop Alfa'
        },
        {
          label: 'July 2020',
          content: 'Archyvault Desktop Beta'
        },
        {
          label: 'August 2020',
          content: 'Archyvault Desktop Release 1.0'
        },
      ]
    }
  ];

  render() {
    return <Grid container className="roadmap">
      {
        this.products.map(({ name, icons, releases }) =>
          <Grid item key={name} className="product" sm={6} md>
            {this.renderPlatforms(icons)}
            <Stepper activeStep={0} orientation="vertical">
              {
                releases.map(({ label, content }) =>
                  <Step key={label}>
                    <StepLabel>
                      <Typography variant="body1">{label}</Typography>
                      <Typography variant="body2">{content}</Typography>
                    </StepLabel>
                  </Step>
                )
              }
            </Stepper>
          </Grid>
        )
      }
    </Grid>;
  }

  private renderPlatforms(icons: IconDefinition[]) {
    return <List className="platforms">
      {
        icons.map((icon, index) =>
          <ListItem key={index}>
            <ListItemIcon>
              <FontAwesomeIcon className="icon" icon={icon} />
            </ListItemIcon>
          </ListItem>
        )
      }
    </List>;
  }
}

export default Roadmap;
