import React, { PureComponent } from 'react';
import { Typography } from '@material-ui/core';
import { Link } from '../../../common/components';
import { archivalStorageCalculator } from '../../../common/helpers';

import './Intro.scss';

export default class Intro extends PureComponent<{}> {
  render() {
    return <div className="intro">
      <Typography variant="body1">
        Only <b>${archivalStorageCalculator.getCost('1TB')}</b>/TB per month.
        Store your images, video, audio, RAW files, or any digital assets in original quality.
        All your content you made for years is always with you.
        It is a perfect backup and archival solution for
        all your files. <Link className="link" to="/register">
        Get {archivalStorageCalculator.initialFreeArchivalStorageGb}GB for Free</Link> or
        read <Link className="link" to="/how-it-works">more</Link>.
      </Typography>
      <img className="media-file-life-cycle"
        src="/images/media-file-life-cycle.svg"
        alt="media file life cycle"
      />
    </div>;
  }
}
