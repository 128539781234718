import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ImageGallery, { ReactImageGalleryItem } from 'react-image-gallery';
import 'react-image-gallery/styles/css/image-gallery.css';
import * as actions from '../../../actions/fileExplorerActions';
import { IAppState } from '../../../store';
import { IDirectory, IFile, FileTypes } from '../../../reducers/fileExplorerReducer';

interface IStateProps {
  items: ReactImageGalleryItem[];
  index: number;
  mediaFiles: IFile[];
}

interface IDispatchProps {
  openInPlace: (file: IFile) => void;
}

interface IOwnProps {
  directory: IDirectory;
  file: IFile;
}

type IProps = IStateProps & IOwnProps & IDispatchProps;

class SliderView extends PureComponent<IProps> {
  private imageGallery?: ImageGallery | null;

  render() {
    const { mediaFiles, items, index, openInPlace } = this.props;
    return <ImageGallery items={items} startIndex={index}
      showFullscreenButton={false} showPlayButton={false} showThumbnails={false}
      onSlide={slideNumber => {
        if (this.imageGallery && index !== this.imageGallery.getCurrentIndex()) {
          openInPlace(mediaFiles[slideNumber]);
        }
      }}
      ref={element => this.imageGallery = element} />;
  }

  componentDidUpdate() {
    if (this.imageGallery && this.imageGallery.getCurrentIndex() !== this.props.index) {
      this.imageGallery.slideToIndex(this.props.index);
    }
  }
}

const mapStateToProps = (store: IAppState, ownProps: IOwnProps): IStateProps => {
  const mediaFiles = ownProps.directory.children.filter(c => c.fileType === FileTypes.IMAGE);
  return {
    mediaFiles,
    items: mediaFiles.map(c => ({
      original: `/images/${c.url}`
    })),
    index: mediaFiles.findIndex(f => f.id === ownProps.file.id)
  };
};

const mapDispatchToProps: IDispatchProps = {
  openInPlace: actions.openInPlace
};

export default connect(mapStateToProps, mapDispatchToProps)(SliderView);
