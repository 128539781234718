export enum TermsAndConditionsActionTypes {
  GET_TERMS_AND_CONDITIONS = 'GET_TERMS_AND_CONDITIONS',
  GET_TERMS_AND_CONDITIONS_SUCCEEDED = 'GET_TERMS_AND_CONDITIONS_SUCCEEDED',
  GET_TERMS_AND_CONDITIONS_FAILED = 'GET_TERMS_AND_CONDITIONS_FAILED'
}

export interface ITermsAndConditionsGetAction {
  type: TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS;
}

export interface ITermsAndConditionsGetSucceededAction {
  type: TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS_SUCCEEDED;
  payload: string;
}

export interface ITermsAndConditionsGetFailedAction {
  type: TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS_FAILED;
}

export type TermsAndConditionsActions = ITermsAndConditionsGetAction
  | ITermsAndConditionsGetSucceededAction
  | ITermsAndConditionsGetFailedAction;

export const get = (): ITermsAndConditionsGetAction => ({
  type: TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS
});

export const getSucceeded = (text: string): ITermsAndConditionsGetSucceededAction => ({
  type: TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS_SUCCEEDED,
  payload: text
});

export const getFailed = (): ITermsAndConditionsGetFailedAction => ({
  type: TermsAndConditionsActionTypes.GET_TERMS_AND_CONDITIONS_FAILED
});
